<template>
  <div class="">
    <div class="rounded-3xl px-2 pb-2 bg-green-30 shadow-lg">
      <div class="text-center text-sm py-4">Add Money to My Savings Pot</div>
      <div class="bg-green-40 rounded-3xl px-4">
        <div class="text-gray-50 text-sm py-5 flex justify-between">
          <div>Select a Pot</div>
          <div class="pr-2">Next</div>
        </div>
        <div class="pb-5 flex justify-between">
          <div class="w-3/4">
            <select
              v-model="pot"
              class="px-5 py-3 rounded-3xl text-sm w-full bg-gray-50 font-semibold focus:outline-none"
            >
              <option value="" disabled selected>-- Select a Pot --</option>
              <option value="House Rent">House Rent Pot</option>
              <option value="Car Purchase">Car Purchase Pot</option>
            </select>
          </div>
          <div
            class="rounded-full bg-gray-50 shadow-md w-10 h-10 flex items-center text-center cursor-pointer"
            @click="amount_page = true"
          >
            <arrow-right-icon :color="'#3F6900'" :width="'w-2 mx-auto'"></arrow-right-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import ArrowRightIcon from '../icons/ArrowRightIcon.vue'
import useToSavingsPot from '@/composables/SaveMoney/to_my_savings_pot'
export default defineComponent({
  components: { ArrowRightIcon },
  setup() {
    const { amount_page, pot } = useToSavingsPot()
    return { amount_page, pot }
  }
})
</script>

<style></style>
