
import HalfIcon from '@/components/icons/HalfIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent, onMounted, ref } from 'vue'
import OnboardOne from '../components/OnboardPages/OnboardOne.vue'
import OnboardTwo from '../components/OnboardPages/OnboardTwo.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    OnboardOne,
    HalfIcon,
    OnboardTwo,
    StatusBarComponent
  },
  setup() {
    const page_1 = ref(true)
    const ionRouter = useIonRouter()

    function next() {
      if (page_1.value) {
        page_1.value = false
      } else {
        ionRouter.navigate('/welcome', 'forward', 'replace')
      }
    }

    onMounted(() => {
      if (isPlatform('android')) {
        StatusBar.setBackgroundColor({ color: '#F3E7D5' })
      }
    })

    return { page_1, next, isPlatform }
  }
})
