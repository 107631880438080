import { useIonRouter } from "@ionic/vue"
import Swal from "sweetalert2"
import { ref } from "vue"

export default () => {
  const ionRouter = useIonRouter()
  const card_label = ref('')
  const icon_content = ref('') as any
  const title = ref('') as any
  const timer = ref(0)


  const createCard = () => {
    if (card_label.value != '') {
      callToast('success', 'card created successfully', 3000)
      setTimeout(() => {
        ionRouter.navigate('/cards', 'forward', 'replace')
      }, 4000);
    } else {
      callToast('info', 'Input a card label', 3000)
    }
  }

  function finish() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: timer.value,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: icon_content.value,
      title: title.value
    })
  }

  function callToast(icon: string, header: string, time: number) {
    icon_content.value = icon
    title.value = header
    timer.value = time
    finish()
  }
  return { card_label, createCard }
}