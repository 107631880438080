<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-3',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#c4c4c4',
      type: String
    }
  }
}
</script>

<style></style>
