import firebase from 'firebase/app'
// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'

interface Base {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
}
let firebaseConfig = {} as Base

if (process.env.NODE_ENV === 'production') {
    firebaseConfig = {
        apiKey: "AIzaSyANp-yUGh9AwjJr2gxJ6Kodc0J8GCODfZo",
        authDomain: "village-square-test.firebaseapp.com",
        projectId: "village-square-test",
        storageBucket: "village-square-test.appspot.com",
        messagingSenderId: "820072225286",
        appId: "1:820072225286:web:f7e985ee5e712607d70187",
        measurementId: "G-W985JZLBS6"
    }
} else {
    firebaseConfig = {
        apiKey: "AIzaSyANp-yUGh9AwjJr2gxJ6Kodc0J8GCODfZo",
        authDomain: "village-square-test.firebaseapp.com",
        projectId: "village-square-test",
        storageBucket: "village-square-test.appspot.com",
        messagingSenderId: "820072225286",
        appId: "1:820072225286:web:f7e985ee5e712607d70187",
        measurementId: "G-W985JZLBS6"
    }
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.auth().useDeviceLanguage();

// firebase utils
const db = firebase.firestore()
const field = firebase.firestore.FieldValue
const auth = firebase.auth()
const fStore = firebase.storage()
const currentUser = firebase.auth().currentUser
const recaptchaVerifier = firebase.auth.RecaptchaVerifier

export default {
    db,
    auth,
    field,
    currentUser,
    fStore,
    recaptchaVerifier
}