import { ref } from "vue"

export default () => {
    const towncrier = ref(true)
    function loadTab(page_id: string) {
        if (page_id === '0') {
            towncrier.value = true
        } else if (page_id === '1') {
            towncrier.value = false
        }
    }
    return { towncrier, loadTab }
}