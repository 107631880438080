
import { defineComponent } from '@vue/runtime-core'
import ArrowLeftIcon from '../icons/ArrowLeftIcon.vue'
import PersonWithBookIcon from '../icons/PersonWithBookIcon.vue'
import useSendAmount from '@/composables/SendMoney/amount_to_save'
import { useIonRouter } from '@ionic/vue'
import SendMoneyAccountDetails from './SendMoneyAccountDetails.vue'

export default defineComponent({
  components: { ArrowLeftIcon, PersonWithBookIcon, SendMoneyAccountDetails },
  setup() {
    const { checkBalance, transfer_amount, balance, next_page } = useSendAmount()
    const ionRouter = useIonRouter()

    return { balance, checkBalance, transfer_amount, next_page, ionRouter }
  }
})
