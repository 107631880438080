<template>
  <svg :class="[width, height]" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 16.5C9.6576 16.5 11.2473 15.8415 12.4194 14.6694C13.5915 13.4973 14.25 11.9076 14.25 10.25C14.25 8.5924 13.5915 7.00269 12.4194 5.83058C11.2473 4.65848 9.6576 4 8 4C6.3424 4 4.75269 4.65848 3.58058 5.83058C2.40848 7.00269 1.75 8.5924 1.75 10.25C1.75 11.9076 2.40848 13.4973 3.58058 14.6694C4.75269 15.8415 6.3424 16.5 8 16.5ZM8 17.75C6.01088 17.75 4.10322 16.9598 2.6967 15.5533C1.29018 14.1468 0.5 12.2391 0.5 10.25C0.5 8.26088 1.29018 6.35322 2.6967 4.9467C4.10322 3.54018 6.01088 2.75 8 2.75C9.98912 2.75 11.8968 3.54018 13.3033 4.9467C14.7098 6.35322 15.5 8.26088 15.5 10.25C15.5 12.2391 14.7098 14.1468 13.3033 15.5533C11.8968 16.9598 9.98912 17.75 8 17.75Z"
      fill="black"
    />
    <path
      d="M8.00023 5.25C8.16599 5.25 8.32497 5.31585 8.44218 5.43306C8.55939 5.55027 8.62523 5.70924 8.62523 5.875L8.61523 10.25C8.61523 10.4158 8.54939 10.5747 8.43218 10.6919C8.31497 10.8092 8.15599 10.875 7.99023 10.875C7.82447 10.875 7.6655 10.8092 7.54829 10.6919C7.43108 10.5747 7.36523 10.4158 7.36523 10.25L7.37523 5.875C7.37523 5.70924 7.44108 5.55027 7.55829 5.43306C7.6755 5.31585 7.83447 5.25 8.00023 5.25Z"
      fill="black"
    />
    <path
      d="M6.75 10.25C6.75 10.5815 6.8817 10.8995 7.11612 11.1339C7.35054 11.3683 7.66848 11.5 8 11.5C8.33152 11.5 8.64946 11.3683 8.88388 11.1339C9.1183 10.8995 9.25 10.5815 9.25 10.25C9.25 9.91848 9.1183 9.60054 8.88388 9.36612C8.64946 9.1317 8.33152 9 8 9C7.66848 9 7.35054 9.1317 7.11612 9.36612C6.8817 9.60054 6.75 9.91848 6.75 10.25ZM8.625 1.5V4H7.375V1.5H5.5C5.33424 1.5 5.17527 1.43415 5.05806 1.31694C4.94085 1.19973 4.875 1.04076 4.875 0.875C4.875 0.70924 4.94085 0.550268 5.05806 0.433058C5.17527 0.315848 5.33424 0.25 5.5 0.25H10.5C10.6658 0.25 10.8247 0.315848 10.9419 0.433058C11.0592 0.550268 11.125 0.70924 11.125 0.875C11.125 1.04076 11.0592 1.19973 10.9419 1.31694C10.8247 1.43415 10.6658 1.5 10.5 1.5H8.625Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
