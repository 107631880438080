<template>
  <svg :class="[width, height]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.427875 14.7773C0.523021 14.8727 0.636037 14.9483 0.760452 14.9999C0.884868 15.0516 1.01824 15.0781 1.15294 15.0781C1.28763 15.0781 1.421 15.0516 1.54542 14.9999C1.66983 14.9483 1.78285 14.8727 1.87799 14.7773L13.0779 3.58577V9.33583C13.0779 9.90158 13.5407 10.3645 14.1063 10.3645C14.672 10.3645 15.1348 9.90158 15.1348 9.33583V1.10676C15.1348 0.54101 14.672 0.078125 14.1063 0.078125H5.87868C5.31303 0.078125 4.85023 0.54101 4.85023 1.10676C4.85023 1.67251 5.31303 2.13539 5.87868 2.13539H11.6277L0.427875 13.3372C0.0370617 13.7281 0.0370617 14.3864 0.427875 14.7773Z"
      fill="#ED713C"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
