import { useIonRouter } from "@ionic/vue"
import Swal from "sweetalert2"
import { getCurrentInstance, ref } from "vue"

export default () => {
    const { emit } = getCurrentInstance() as any
    const acct_number = ref('')
    const bank_name = ref('')
    const icon_content = ref('') as any
    const title = ref('') as any
    const timer = ref(0)
    const ionRouter = useIonRouter()

    function goBack() {
        emit('go-back', true)
    }
    function validate(val: number) {
        if (bank_name.value !== '' && acct_number.value !== '') {
            icon_content.value = 'success'
            title.value = '&#8358;' + val.toLocaleString() + ' was transferred successfully.'
            timer.value = 4000
            ionRouter.navigate('/home', 'forward', 'replace')
        } else {
            icon_content.value = 'info'
            title.value = 'All fields are required.'
            timer.value = 4000
        }
    }
    function finish(val: number) {
        validate(val)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: timer.value,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon_content.value,
            title: title.value
        })
    }
    return { goBack, acct_number, bank_name, finish }
}