<template>
  <svg :class="[width, height]" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4316 22.6191C6.78164 22.6191 2.18164 18.0191 2.18164 12.3691C2.18164 6.71914 6.78164 2.11914 12.4316 2.11914C18.0816 2.11914 22.6816 6.71914 22.6816 12.3691C22.6816 18.0191 18.0816 22.6191 12.4316 22.6191ZM12.4316 3.61914C7.60164 3.61914 3.68164 7.54914 3.68164 12.3691C3.68164 17.1891 7.60164 21.1191 12.4316 21.1191C17.2616 21.1191 21.1816 17.1891 21.1816 12.3691C21.1816 7.54914 17.2616 3.61914 12.4316 3.61914Z"
      fill="#292D32"
    />
    <path
      d="M22.9321 23.6196C22.7421 23.6196 22.5521 23.5496 22.4021 23.3996L20.4021 21.3996C20.1121 21.1096 20.1121 20.6296 20.4021 20.3396C20.6921 20.0496 21.1721 20.0496 21.4621 20.3396L23.4621 22.3396C23.7521 22.6296 23.7521 23.1096 23.4621 23.3996C23.3121 23.5496 23.1221 23.6196 22.9321 23.6196Z"
      fill="#292D32"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-6',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
