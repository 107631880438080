import axios from 'axios'
import { useAuthorizationCode } from '@/store/authorization_code'

export default () => {
  const authorizationCodeModule = useAuthorizationCode()
  const BASE64ENCODE = authorizationCodeModule.base_64
  let baseURL = ''

  if (process.env.NODE_ENV == 'production') {
    // baseURL = 'https://apps.qa.interswitchng.com/passport/oauth'
    baseURL = 'https://sandbox.interswitchng.com/passport/oauth'
  } else {
    baseURL = 'https://sandbox.interswitchng.com/passport/oauth'
  }

  const accessTokenObject = axios.create({
    baseURL: baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + BASE64ENCODE
    }
  })
  const accessToken = () => {
    return accessTokenObject.post<any>('/token?grant_type=client_credentials')
  }
  return { accessToken }
}
