<template>
  <div>
    <div
      class="bg-main-500 rounded-2xl shadow"
      :class="{ 'mt-4': index !== 0 }"
      v-for="(deal, index) in market"
      :key="index"
    >
      <img src="/img/announce.jpg" alt="annouce" class="rounded-t-2xl w-full" />
      <div class="px-4 py-3">
        <div class="text-xs font-medium">
          {{ deal.content }}
        </div>
        <div class="flex text-xs font-semibold pt-3">
          <div class="line-through text-gray-400">&#8358;{{ deal.old_amount.toLocaleString() }}</div>
          <div class="pl-3 filter drop-shadow">&#8358;{{ deal.new_amount.toLocaleString() }}</div>
        </div>
        <div class="flex justify-between items-center text-xs pt-2">
          <div class="flex font-light">
            <div class="">{{ deal.slots }} slots left</div>
            <div class="pl-3">{{ deal.deadline }}</div>
          </div>
          <div
            class="bg-card-100 rounded-full uppercase shadow hover:shadow-md px-3 py-2 text-gray-50"
            @click="() => ionRouter.navigate('/village_market_details', 'forward', 'replace')"
          >
            Claim Now
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import useVillageMarket from '@/composables/Announcement/village_market'
import { useIonRouter } from '@ionic/vue'

export default defineComponent({
  setup() {
    const { market } = useVillageMarket()
    const ionRouter = useIonRouter()
    return { market, ionRouter }
  }
})
</script>

<style></style>
