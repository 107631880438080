import { ref, watchEffect } from "vue"

export default () => {
    const timeline = ref([
        {
            title: '3 Months',
            interval_count: [
                { count: 12, words: '12 Weeks', time: 'Every Week' },
                { count: 6, words: '6 Weeks', time: 'Every 2 Weeks' },
                { count: 3, words: '3 Months', time: 'Every Month' }
            ],
            days: 90
        },
        {
            title: '6 Months',
            interval_count: [
                { count: 24, words: '24 Weeks', time: 'Every Week' },
                { count: 12, words: '12 Weeks', time: 'Every 2 Weeks' },
                { count: 6, words: '6 Months', time: 'Every Month' }
            ],
            days: 182
        },
        {
            title: '1 Year',
            interval_count: [
                { count: 48, words: '48 Weeks', time: 'Every Week' },
                { count: 24, words: '24 Weeks', time: 'Every 2 Weeks' },
                { count: 12, words: '12 Months', time: 'Every Month' }
            ],
            days: 365
        }
    ])
    const frequency_index = ref(null)
    const duration_index = ref(null)
    const village_name = ref('')
    const village_category = ref(null)
    const village_categories = ref([
        'Office / Business',
        'Housing',
        'Beauty/Wellness',
        'Travel',
        'Awareness',
        'Healthcare',
        'Education',
        'Events',
        'Sports',
        'Fashion',
        'Entertainment / Music',
        'Financial Freedom',
        'Food / Agriculture',
        'Family',
        'Religion',
        'Electronics',
        'Vehicles',
        'Personal development',
        'Professionals',
        'Others'
    ])
    const total_amount = ref('')
    const forward_date = ref(Date.now())
    const interest_page = ref(false)

    watchEffect(() => {
        calculateDate()
    })

    function calculateDate() {
        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()
        const hour = d.getHours()
        const minutes = d.getMinutes()
        if (duration_index.value !== null) {
            forward_date.value = new Date(year, month, day + timeline.value[duration_index.value].days, hour, minutes).getTime()
        }
    }

    function validator(): boolean {
        if (frequency_index.value !== null && duration_index.value !== null && village_name.value !== '' && total_amount.value !== '' && village_category.value !== null) {
            return true
        } else {
            return false
        }
    }

    function nextPage() {
        if (validator()) {
            interest_page.value = true
        }
    }

    return { village_name, total_amount, village_category, duration_index, frequency_index, timeline, forward_date, interest_page, village_categories, nextPage }
}