
import SelectedCards from '@/components/CardsPage/List/SelectedCards.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    InfoIcon2,
    SelectedCards,
    StatusBarComponent
  },
  setup() {
    const ionRouter = useIonRouter()

    return { ionRouter, isPlatform }
  }
})
