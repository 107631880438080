
import { IonContent, IonPage } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import VerificationPage from '@/components/WelcomePage/VerificationPage.vue'
import { defineComponent, onMounted } from '@vue/runtime-core'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HalfIcon,
    VerificationPage,
    StatusBarComponent
  },
  setup() {
    onMounted(() => {
      if (isPlatform('android')) {
        StatusBar.setBackgroundColor({ color: '#F2F2F2' })
      }
    })
    return { isPlatform }
  }
})
