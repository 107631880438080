
import ArrowLeftIcon from '../../icons/ArrowLeftIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useCreateVillagePot from '@/composables/VillagePot/create_village_pot'
import DateCreator from '../../DateCreator.vue'
import VillageInterestPage from './VillageInterestPage.vue'
export default defineComponent({
  components: { ArrowLeftIcon, DateCreator, VillageInterestPage },
  setup() {
    const ionRouter = useIonRouter()
    const {
      village_name,
      total_amount,
      village_category,
      duration_index,
      frequency_index,
      timeline,
      forward_date,
      interest_page,
      village_categories,
      nextPage
    } = useCreateVillagePot()
    return {
      ionRouter,
      village_name,
      total_amount,
      village_category,
      duration_index,
      frequency_index,
      timeline,
      forward_date,
      interest_page,
      village_categories,
      nextPage
    }
  }
})
