import { ref } from "vue"

export default () => {
    const personal_pots = ref([
        { pot_name: 'House Rent', target_amount: 1300000 },
        { pot_name: 'Dubai Trip', target_amount: 5000000 },
        { pot_name: 'Laptop', target_amount: 600000 },
        { pot_name: 'Land Purchase', target_amount: 15000000 },
        { pot_name: 'Car Purchase', target_amount: 9000000 }
    ])

    const village_pots = ref([
        { pot_name: 'Lekki Phase II', target_amount: 90000000 },
        { pot_name: 'Adenuga Str., Eti-Osa', target_amount: 15000000 },
        { pot_name: 'Gani Adedayo Str., Eti-Osa', target_amount: 6000000 }
    ])
    return { personal_pots, village_pots }
}