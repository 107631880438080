<template>
  <svg :class="[width, height]" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8991 27H22.7569C30.0645 27 35.9877 21.404 35.9877 14.5C35.9877 7.596 30.0645 2 22.7569 2H15.3477C8.04007 2 2.11692 7.596 2.11692 14.5C2.11692 22.56 6.98161 27.38 12.0982 30.286C14.666 31.744 17.2529 32.686 19.2068 33.263C19.4482 33.334 19.68 33.4 19.8991 33.46V27ZM22.016 36C22.016 36 21.2073 35.871 19.8991 35.537C14.3845 34.125 0 29.05 0 14.5C0 6.492 6.87152 0 15.3477 0H22.7569C31.2331 0 38.1046 6.492 38.1046 14.5C38.1046 22.508 31.2331 29 22.7569 29H22.016V36Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.9589 6.48209C24.0491 7.16609 24.8705 8.27509 24.8705 9.76209C24.8705 11.3401 24.1973 12.5001 23.106 13.2101C22.6752 13.4857 22.1997 13.693 21.6983 13.8241V15.3561C21.6983 15.7539 21.531 16.1354 21.2333 16.4167C20.9355 16.698 20.5317 16.8561 20.1106 16.8561C19.6895 16.8561 19.2857 16.698 18.9879 16.4167C18.6902 16.1354 18.5229 15.7539 18.5229 15.3561V12.5591C18.5228 12.1785 18.6758 11.8121 18.951 11.5342C19.2261 11.2562 19.6028 11.0875 20.0047 11.0621C20.5911 11.0251 21.0357 10.9151 21.3035 10.7401C21.412 10.6722 21.4997 10.5783 21.5575 10.4681C21.621 10.3481 21.6951 10.1341 21.6951 9.76109C21.6951 9.44809 21.566 9.20909 21.2008 8.97909C20.7859 8.71909 20.1191 8.52709 19.3178 8.50309C18.5261 8.47909 17.7566 8.62509 17.1903 8.88409C16.623 9.14409 16.4144 9.43609 16.3562 9.65109C16.3044 9.8419 16.2133 10.0212 16.0881 10.1787C15.9629 10.3363 15.8061 10.469 15.6266 10.5693C15.4471 10.6696 15.2485 10.7355 15.0421 10.7632C14.8356 10.791 14.6254 10.7801 14.4235 10.7311C14.2215 10.6821 14.0317 10.596 13.865 10.4777C13.6982 10.3595 13.5578 10.2113 13.4516 10.0418C13.3455 9.8722 13.2757 9.68455 13.2463 9.48952C13.2169 9.29449 13.2285 9.0959 13.2803 8.90509C13.6455 7.56409 14.7071 6.68809 15.8058 6.18509C16.9066 5.68009 18.2011 5.46709 19.4184 5.50409C20.6282 5.54009 21.9195 5.82809 22.9589 6.48209Z"
      :fill="color"
    />
    <path
      d="M22.228 21C22.228 21.5304 22.005 22.0391 21.608 22.4142C21.211 22.7893 20.6725 23 20.1111 23C19.5496 23 19.0112 22.7893 18.6142 22.4142C18.2172 22.0391 17.9941 21.5304 17.9941 21C17.9941 20.4696 18.2172 19.9609 18.6142 19.5858C19.0112 19.2107 19.5496 19 20.1111 19C20.6725 19 21.211 19.2107 21.608 19.5858C22.005 19.9609 22.228 20.4696 22.228 21Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-6',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
