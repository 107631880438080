import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_icon = _resolveComponent("loader-icon")!
  const _component_phone_verification = _resolveComponent("phone-verification")!
  const _component_verification_code = _resolveComponent("verification-code")!
  const _component_register_page = _resolveComponent("register-page")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_loader_icon, { loading: _ctx.login_loader }, null, 8, ["loading"]),
    _withDirectives(_createVNode(_component_phone_verification, {
      class: _normalizeClass(["animate__animated h-85-screen", { animate__fadeInLeft: _ctx.enter_phone }]),
      onSendConfirmation: _ctx.sendConfirmation,
      onVerified: _ctx.verifiedPhone
    }, null, 8, ["class", "onSendConfirmation", "onVerified"]), [
      [_vShow, _ctx.enter_phone]
    ]),
    (_ctx.verify)
      ? (_openBlock(), _createBlock(_component_verification_code, {
          key: 0,
          class: _normalizeClass(["animate__animated h-85-screen", { animate__fadeInLeft: _ctx.verify }]),
          confirmation: _ctx.confirmation,
          onVerified: _ctx.verifiedPhone,
          onShowRegister: _ctx.showRegister
        }, null, 8, ["class", "confirmation", "onVerified", "onShowRegister"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_register_page, {
      class: _normalizeClass(["animate__animated", { animate__fadeInLeft: _ctx.show_register }])
    }, null, 8, ["class"]), [
      [_vShow, _ctx.show_register]
    ])
  ]))
}