
import PeopleIcon from '@/components/icons/PeopleIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: { PeopleIcon },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
