
import HalfIcon from '../icons/HalfIcon.vue'
import MenuPotIcon from '../icons/MenuPotIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { MenuPotIcon, HalfIcon },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
