<template>
  <div class="h-85-screen flex flex-col justify-between py-4">
    <div>
      <div class="text-card-100 pb-4 font-medium flex justify-center">
        <info-icon-2 :color="'#ED6500'"></info-icon-2>
        <div class="pl-2">Simply tap on any row to edit</div>
      </div>
      <div>
        <div>
          <small class="animate__animated animate__bounceIn ml-2" v-show="pot.pot_name !== ''">Pot Name</small>
          <input
            type="text"
            v-model="pot.pot_name"
            placeholder="Pot Name"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': pot.target_amount === '', 'mt-2': pot.target_amount !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="pot.target_amount !== ''"
            >Target Amount</small
          >
          <input
            type="text"
            v-model="pot.target_amount"
            placeholder="Target Amount"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': pot.frequency === '', 'mt-2': pot.frequency !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="pot.frequency !== ''">Frequency</small>
          <input
            type="text"
            v-model="pot.frequency"
            placeholder="Frequency"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': pot.maturity_date === '', 'mt-2': pot.maturity_date !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="pot.maturity_date !== ''"
            >Maturity Date</small
          >
          <input
            type="text"
            v-model="pot.maturity_date"
            placeholder="Maturity Date"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
      </div>
    </div>
    <div class="w-full pt-6 pb-3">
      <div class="flex justify-between">
        <div class="bg-card-300 rounded-3xl p-2 hover:shadow-lg w-5/12 mx-auto">
          <div class="bg-ash-40 rounded-2xl py-3">
            <div class="flex justify-between items-center">
              <div class="text-lg text-gray-50 font-semibold text-center w-full cursor-pointer">Disable</div>
            </div>
          </div>
        </div>
        <div class="bg-card-150 rounded-3xl p-2 hover:shadow-lg w-5/12 mx-auto">
          <div class="bg-card-100 rounded-2xl py-3">
            <div class="flex justify-between items-center">
              <div class="text-lg text-gray-50 font-semibold text-center w-full cursor-pointer">Update</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon2 from '../../icons/InfoIcon2.vue'
import useEditPotDetails from '@/composables/Settings/edit_pot_details'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { InfoIcon2 },
  setup() {
    const { pot } = useEditPotDetails()

    return { pot }
  }
})
</script>

<style></style>
