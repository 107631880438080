import { useIonRouter } from "@ionic/vue"
import Swal from "sweetalert2"
import { ref } from "vue"

export default () => {
    const transfer_amount = ref('')
    const timer = ref(0)
    const title = ref('') as any
    const icon_content = ref('') as any
    const ionRouter = useIonRouter()

    function goBack() {
        ionRouter.navigate('/add_money', 'forward', 'replace')
    }

    function checkBalance() {
        if (transfer_amount.value !== '') {
            icon_content.value = 'success'
            title.value = '&#8358;' + parseFloat(transfer_amount.value).toLocaleString() + ' was added successfully.'
            timer.value = 3000
            goBack()
        } else {
            icon_content.value = 'info'
            title.value = 'Input amount to transfer'
            timer.value = 4000
        }
    }

    function finish() {
        checkBalance()
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: timer.value,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon_content.value,
            title: title.value
        })
    }

    return { finish, transfer_amount }
}