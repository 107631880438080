import axios from 'axios'
import { useLogin } from '@/store/login'

type AxiosCreate = {
  baseURL: string;
  headers: any;
  port: number;
}

export default () => {
  const access_token = useLogin()

  const createObject: AxiosCreate = {
    baseURL: `https://api-gateway.interswitchng.com`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token.token
    },
    port: 443,
  }
  
  const apiGateway = axios.create(createObject)

  const bankCodes = () => {
    return apiGateway.get<any>('generic-wallet/api/v1/admin/account/banks')
  }

  return { bankCodes }
}
