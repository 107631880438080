import { ref } from "vue"

export default () => {
    const personal_pots = ref([
        {
            title: 'Office Rent',
            saved_amount: 3000000,
            end_point: 4000000,
            next_pay_day: 'Mar 28',
            width: 'w-1/3'
        },
        {
            title: 'House Rent',
            saved_amount: 1400000,
            end_point: 2000000,
            next_pay_day: 'Apr 02',
            width: 'w-8/12'
        },
        {
            title: 'Laptop',
            saved_amount: 400000,
            end_point: 700000,
            next_pay_day: 'Apr 14',
            width: 'w-7/12'
        },
        {
            title: 'Land Purchase',
            saved_amount: 4000000,
            end_point: 12000000,
            next_pay_day: 'Aug 16',
            width: 'w-1/3'
        },
        {
            title: 'Building Project',
            saved_amount: 120000000,
            end_point: 350000000,
            next_pay_day: 'Nov 16',
            width: 'w-2/5'
        }
    ])
    return { personal_pots }
}