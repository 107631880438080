<template>
  <svg :class="[width, height]" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.9863 60.5343L40.0955 79.9492L80.0007 40.001H60.5119L39.9863 60.5343Z" fill="#ECC19A" />
    <path d="M39.9863 19.4145L40.0955 0L80 39.9473H60.5116L39.9863 19.4145Z" fill="white" />
    <path d="M40.0137 60.5855L39.9045 80L-4.57764e-05 40.0527H19.4884L40.0137 60.5855Z" fill="white" />
    <path d="M40.0137 19.4145L39.9045 0L-4.57764e-05 39.9473H19.4884L40.0137 19.4145Z" fill="#ECC19A" />
    <path d="M60.4515 40.0018H19.3945L39.9259 60.5352L60.4515 40.0018Z" fill="#ED6500" />
    <path d="M60.4522 39.9365H19.3945L39.9262 19.4023L60.4522 39.9365Z" fill="#645F57" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-16',
      type: String
    },
    height: {
      default: 'h-16',
      type: String
    }
  }
}
</script>

<style></style>
