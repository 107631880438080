
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import ToVillageSquareAccount from '@/components/AddMoney/ToVillageSquareAccount.vue'
import { defineComponent } from '@vue/runtime-core'
import PersonWithBookIcon from '@/components/icons/PersonWithBookIcon.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, HalfIcon, ArrowLeftIcon, ToVillageSquareAccount, PersonWithBookIcon, StatusBarComponent },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter, isPlatform }
  }
})
