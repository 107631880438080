
import { IonContent, IonPage } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import MenuPotIcon from '@/components/icons/MenuPotIcon.vue'
import SavingsDetailsPersonal from '@/components/PersonalPot/Create/SavingsDetailsPersonal.vue'
import { defineComponent } from '@vue/runtime-core'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, HalfIcon, MenuPotIcon, SavingsDetailsPersonal, StatusBarComponent },
  setup() {
    return { isPlatform }
  }
})
