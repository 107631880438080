
import { defineComponent } from '@vue/runtime-core'
import ArrowLeftIcon from '../icons/ArrowLeftIcon.vue'
import PersonWithBookIcon from '../icons/PersonWithBookIcon.vue'
import useSaveAmount from '@/composables/SaveMoney/save_amount'

export default defineComponent({
  components: { ArrowLeftIcon, PersonWithBookIcon },
  props: {
    pot: String
  },
  setup(props) {
    const pot_name = props.pot as any
    const { goBack, finish, transfer_amount } = useSaveAmount()

    return { pot_name, goBack, finish, transfer_amount }
  }
})
