declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any;
    }
}
import { getCurrentInstance, onMounted, ref } from "vue"
import fb from '@/services/firebase'
import Swal from "sweetalert2";

export default () => {
    const { emit } = getCurrentInstance() as any
    const phone = ref('')
    const loading = ref(false)

    function verifyPhone(val: boolean) {
        emit('verified', val)
    }
    function sendConfirmationResult() {
        emit('send-confirmation', window.confirmationResult)
    }

    function validator() {
        if (phone.value !== '') {
            return true
        } else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'warning',
                title: 'Please input phone number.'
            })
        }
    }

    function verificationCode() {
        if (validator()) {
            const phoneNumber = phone.value
            const appVerifier = window.recaptchaVerifier;
            loading.value = true
            fb.auth.signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    verifyPhone(true)
                    sendConfirmationResult()
                    loading.value = false
                    // ...
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    console.log(error)
                });
        }
    }
    onMounted(() => {
        window.recaptchaVerifier = new fb.recaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response: any) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                console.log(response)
            }
        });
    })
    return { verificationCode, phone, loading }
}