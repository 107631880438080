
import { IonContent, IonPage } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import MenuPotIcon from '@/components/icons/MenuPotIcon.vue'
import VillageSavingsDetails from '@/components/VillagePot/Create/VillageSavingsDetails.vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: { IonContent, IonPage, HalfIcon, MenuPotIcon, VillageSavingsDetails },
  setup() {
    return {}
  }
})
