import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_bar_component = _resolveComponent("status-bar-component")!
  const _component_menu_icon = _resolveComponent("menu-icon")!
  const _component_notification_icon = _resolveComponent("notification-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["px-6 py-5 xs:py-7 bg-main-500 fixed top-0 left-0 right-0 z-10", { 'shadow-md': $props.show_shadow }])
  }, [
    ($setup.isPlatform('iphone'))
      ? (_openBlock(), _createBlock(_component_status_bar_component, {
          key: 0,
          height: 'h-7',
          color: 'bg-main-500'
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "w-7 h-7 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleMenu(true)))
      }, [
        _createVNode(_component_menu_icon, {
          color: '#1f2937',
          width: 'w-5 xs:w-6'
        })
      ]),
      _createElementVNode("div", {
        class: "bg-card-100 rounded-full w-10 h-10 text-center py-1",
        onClick: _cache[1] || (_cache[1] = () => $setup.ionRouter.navigate('/announcements/0', 'forward', 'replace'))
      }, [
        _createVNode(_component_notification_icon, {
          width: 'w-5 xs:w-6 mx-auto',
          height: 'h-auto',
          color: 'text-gray-50 cursor-pointer'
        })
      ])
    ])
  ], 2))
}