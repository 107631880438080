<template>
  <div>
    <div class="sticky h-screen bg-gray-50 rounded-t-3xl py-4">
      <div class="border-b-4 border-gray-500 rounded-2xl mb-1 w-16 mx-auto"></div>
      <div class="text-center text-xl font-semibold">Your Recent Transactions</div>
      <div class="h-90-screen overflow-y-auto px-4 xxs:px-5 mt-2">
        <div class="bg-main-500 px-3 py-3 rounded-2xl">
          <div
            class="flex justify-between items-center px-3 py-2 bg-main-400 shadow rounded-xl"
            :class="{ 'my-3': index !== 0 }"
            v-for="(item, index) in count"
            :key="index"
          >
            <div class="bg-gray-50 p-3 rounded-xl">
              <inflow-icon v-if="index % 2 !== 0"></inflow-icon>
              <outflow-icon v-if="index % 2 === 0"></outflow-icon>
            </div>
            <div class="text-sm font-medium">
              <div>Inflow from Dangote</div>
              <div class="text-red-500" :class="{ 'text-red-500': index % 2 === 0, 'text-green-500': index % 2 !== 0 }">
                &#8358;{{ (15000 + item).toLocaleString() }}
              </div>
            </div>
            <div class="text-xs">
              <div class="text-gray-500">March {{ item }}</div>
              <div class="text-gray-400">{{ item.toFixed(2) }}am</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import InflowIcon from '@/components/icons/InflowIcon.vue'
import OutflowIcon from '@/components/icons/OutflowIcon.vue'
export default {
  components: { InflowIcon, OutflowIcon },
  setup() {
    const count = ref([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29
    ])
    return { count }
  }
}
</script>

<style></style>
