
import LogoIcon from '../icons/LogoIcon.vue'
import useSlides from '@/composables/HomePage/slides'
import { computed, defineComponent, onMounted } from '@vue/runtime-core'
import HalfIcon from '../icons/HalfIcon.vue'
import { isPlatform } from '@ionic/vue'
import { useLogin } from '@/store/login'

export default defineComponent({
  components: { LogoIcon, HalfIcon },
  setup() {
    const { switchSlide, negative_margin, slide } = useSlides()
    const login = useLogin()

    const user = computed(() => login.user)
    function pageWidth(): number {
      const page = document.getElementById('page')
      if (page !== null) {
        return page.offsetWidth
      } else {
        return 0
      }
    }
    onMounted(() => {
      window.setInterval(() => {
        switchSlide(pageWidth())
      }, 6000)
    })
    return { negative_margin, slide, isPlatform, user }
  }
})
