
import { useIonRouter } from '@ionic/vue'
import TransactionCardIcon from '@/components/icons/TransactionCardIcon.vue'
import MasterCard from '@/components/icons/MasterCard.vue'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'
import useCreateCard from '@/composables/Cards/create_card'
export default {
  components: { TransactionCardIcon, MasterCard, InfoIcon2 },
  setup() {
    const ionRouter = useIonRouter()
    const { card_label, createCard } = useCreateCard()

    return { ionRouter, card_label, createCard }
  }
}
