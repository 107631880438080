
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted } from '@vue/runtime-core'
import TimerIcon from '@/components/icons/TimerIcon.vue'
import useVillageMarketDetails from '@/composables/Announcement/village_market_details'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: { IonContent, IonPage, ArrowLeftIcon, TimerIcon },
  setup() {
    const ionRouter = useIonRouter()
    const { countDown, hours, minutes, seconds, padLeadingZeros } = useVillageMarketDetails()

    onMounted(() => {
      setInterval(() => {
        countDown()
      }, 1000)
    })
    onBeforeMount(() => {
      if (isPlatform('android')) {
        StatusBar.setOverlaysWebView({ overlay: true })
      }
    })
    onBeforeUnmount(() => {
      if (isPlatform('android')) {
        StatusBar.setOverlaysWebView({ overlay: false })
      }
    })

    return { ionRouter, hours, minutes, seconds, padLeadingZeros, isPlatform }
  }
})
