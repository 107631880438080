
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import VillagePotCard from '@/components/VillagePot/View/VillagePotCard.vue'
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useToggleBetweenPages from '@/composables/VillagePot/nav_links'
import VillagePotAbout from '@/components/VillagePot/View/VillagePotAbout.vue'
import VillagePotTransactions from '@/components/VillagePot/View/VillagePotTransactions.vue'
import VillagePotVillager from '@/components/VillagePot/View/VillagePotVillager.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    VillagePotCard,
    VillagePotAbout,
    VillagePotTransactions,
    VillagePotVillager,
    StatusBarComponent
  },
  setup() {
    const ionRouter = useIonRouter()
    const { toggleBetweenPages, about, transactions, villagers } = useToggleBetweenPages()
    return { ionRouter, toggleBetweenPages, about, transactions, villagers, isPlatform }
  }
})
