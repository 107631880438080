<template>
  <div class="py-5">
    <div class="text-xl font-medium">Village Pots</div>
    <div
      class="w-full rounded-xl px-5 py-3 my-2 bg-card-360 hover:bg-card-100 hover:text-gray-50 hover:shadow-md font-semibold"
      v-for="(pot, index) in village_pots"
      :key="index"
      @click="openPage"
    >
      <div class="flex justify-between">
        <div>{{ pot.pot_name }}</div>
        <div>&#8358;{{ pot.target_amount.toLocaleString() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useEditPot from '@/composables/Settings/edit_pot'

export default defineComponent({
  setup() {
    const ionRouter = useIonRouter()
    const { village_pots } = useEditPot()

    function openPage() {
      setTimeout(() => {
        ionRouter.navigate('/settings/edit_pot', 'forward', 'replace')
      }, 500)
    }

    return { village_pots, openPage }
  }
})
</script>

<style></style>
