
import CircularPotIcon from '../icons/CircularPotIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { CircularPotIcon },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
