
import DateCreator from '@/components/DateCreator.vue'
import useCreatePersonalPot from '@/composables/PersonalPot/create_personal_pot'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { useIonRouter } from '@ionic/vue'
import InterestPage from './InterestPage.vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: { DateCreator, ArrowLeftIcon, InterestPage },
  setup() {
    const {
      timeline,
      frequency_index,
      duration_index,
      pot_name,
      amount,
      total_amount,
      forward_date,
      nextPage,
      interest_page
    } = useCreatePersonalPot()
    const ionRouter = useIonRouter()
    return {
      timeline,
      ionRouter,
      frequency_index,
      duration_index,
      pot_name,
      amount,
      total_amount,
      forward_date,
      nextPage,
      interest_page
    }
  }
})
