<template>
  <svg :class="[width, height]" viewBox="0 0 57 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.4141 14.3747L28.4921 27.9648L57 0.00159264H43.0774L28.4141 14.3747Z" fill="#ECC19A" />
    <path d="M28.4141 14.3747L28.4921 27.9648L57 0.00159264H43.0774L28.4141 14.3747Z" fill="#ECC19A" />
    <path d="M28.5859 14.4101L28.5079 28L0.000444412 0.0374146H13.9228L28.5859 14.4101Z" fill="white" />
    <path d="M28.5859 14.4101L28.5079 28L0.000444412 0.0374146H13.9228L28.5859 14.4101Z" fill="#F2F2F2" />
    <path d="M43.2058 -1.81198e-05H13.875L28.5425 14.373L43.2058 -1.81198e-05Z" fill="#ED6500" />
    <path d="M43.2058 -1.81198e-05H13.875L28.5425 14.373L43.2058 -1.81198e-05Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-16',
      type: String
    },
    height: {
      default: 'h-16',
      type: String
    },
    color: {
      default: '#979797',
      type: String
    }
  }
}
</script>

<style></style>
