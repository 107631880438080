import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-gray-50 max-w-md mx-auto min-h-screen xs:shadow-2xl relative overflow-x-auto" }
const _hoisted_2 = { class: "flex flex-col justify-between h-screen" }
const _hoisted_3 = { class: "px-4 xxs:px-6 py-14" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_bar_component = _resolveComponent("status-bar-component")!
  const _component_half_icon = _resolveComponent("half-icon")!
  const _component_arrow_left_icon = _resolveComponent("arrow-left-icon")!
  const _component_to_village_square_account = _resolveComponent("to-village-square-account")!
  const _component_person_with_book_icon = _resolveComponent("person-with-book-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "animate__animated animate__fadeInLeft" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isPlatform('iphone'))
              ? (_openBlock(), _createBlock(_component_status_bar_component, {
                  key: 0,
                  color: 'bg-gray-50'
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["absolute right-0 -mt-5", { 'top-0': !_ctx.isPlatform('iphone'), 'top-9': _ctx.isPlatform('iphone') }])
            }, [
              _createVNode(_component_half_icon, {
                color: '#ED6500',
                width: 'w-20',
                height: 'h-20'
              })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["absolute left-5 cursor-pointer", { 'top-3': !_ctx.isPlatform('iphone'), 'top-12': _ctx.isPlatform('iphone') }]),
              onClick: _cache[0] || (_cache[0] = () => _ctx.ionRouter.navigate('/home', 'forward', 'replace'))
            }, [
              _createVNode(_component_arrow_left_icon, {
                width: 'w-4',
                color: '#474747'
              })
            ], 2),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_to_village_square_account)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_person_with_book_icon)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}