<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.75 16.6992H2.5C1.9875 16.6992 1.5625 16.2742 1.5625 15.7617C1.5625 15.2492 1.9875 14.8242 2.5 14.8242H23.75C24.2625 14.8242 24.6875 15.2492 24.6875 15.7617C24.6875 16.2742 24.2625 16.6992 23.75 16.6992Z"
      :fill="color"
    />
    <path
      d="M19.025 27.1875H7.22504C2.93754 27.1875 1.5625 25.825 1.5625 21.5875V12.85C1.5625 9.4875 2.32503 7.5 6.20003 7.275C6.52503 7.2625 6.86254 7.25 7.22504 7.25H19.025C23.3125 7.25 24.6875 8.6125 24.6875 12.85V21.7875C24.6375 25.875 23.2625 27.1875 19.025 27.1875ZM7.22504 9.125C6.88754 9.125 6.57504 9.1375 6.28754 9.15C4.05004 9.2875 3.4375 9.7625 3.4375 12.85V21.5875C3.4375 24.7875 3.96254 25.3125 7.22504 25.3125H19.025C22.25 25.3125 22.775 24.8125 22.8125 21.775V12.85C22.8125 9.65 22.2875 9.125 19.025 9.125H7.22504Z"
      :fill="color"
    />
    <path
      d="M23.75 22.725C23.5125 22.725 23.275 22.6375 23.1125 22.475C22.925 22.3 22.8125 22.05 22.8125 21.7875V12.85C22.8125 9.65 22.2875 9.125 19.025 9.125H7.22504C6.88754 9.125 6.57504 9.1375 6.28754 9.15C6.03754 9.1625 5.78748 9.0625 5.59998 8.8875C5.41248 8.7125 5.3125 8.4625 5.3125 8.2C5.3625 4.125 6.73754 2.8125 10.975 2.8125H22.775C27.0625 2.8125 28.4375 4.175 28.4375 8.4125V17.15C28.4375 20.5125 27.675 22.5 23.8 22.725C23.7875 22.725 23.7625 22.725 23.75 22.725ZM7.22504 7.25H19.025C23.3125 7.25 24.6875 8.6125 24.6875 12.85V20.75C26.1375 20.4875 26.5625 19.7375 26.5625 17.15V8.4125C26.5625 5.2125 26.0375 4.6875 22.775 4.6875H10.975C8.12504 4.6875 7.38754 5.075 7.22504 7.25Z"
      :fill="color"
    />
    <path
      d="M8.70082 23.1992H6.55078C6.03828 23.1992 5.61328 22.7742 5.61328 22.2617C5.61328 21.7492 6.03828 21.3242 6.55078 21.3242H8.70082C9.21332 21.3242 9.63832 21.7492 9.63832 22.2617C9.63832 22.7742 9.22582 23.1992 8.70082 23.1992Z"
      :fill="color"
    />
    <path
      d="M15.6867 23.1992H11.3867C10.8742 23.1992 10.4492 22.7742 10.4492 22.2617C10.4492 21.7492 10.8742 21.3242 11.3867 21.3242H15.6867C16.1992 21.3242 16.6242 21.7492 16.6242 22.2617C16.6242 22.7742 16.2117 23.1992 15.6867 23.1992Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-8',
      type: String
    },
    height: {
      default: 'h-8',
      type: String
    },
    color: {
      default: '#C4C4C4',
      type: String
    }
  }
}
</script>

<style></style>
