
import HousingIcon from '@/components/icons/HousingIcon.vue'
import TravelHolidaysIcon from '@/components/icons/TravelHolidaysIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useListVillagePots from '@/composables/VillagePot/list_village_pots'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import PeopleIcon from '@/components/icons/PeopleIcon.vue'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'

export default defineComponent({
  components: { HousingIcon, TravelHolidaysIcon, HalfIcon, PeopleIcon, InfoIcon2 },
  setup() {
    const ionRouter = useIonRouter()
    const { village_pots } = useListVillagePots()
    return { ionRouter, village_pots }
  }
})
