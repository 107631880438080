
import ArrowLeftIcon from '../../icons/ArrowLeftIcon.vue'
import useInterestPage from '@/composables/VillagePot/interest_page'
import InfoIcon2 from '../../icons/InfoIcon2.vue'
import DateCreator from '../../DateCreator.vue'
import Swal from 'sweetalert2'
import { defineComponent } from '@vue/runtime-core'
import { useIonRouter } from '@ionic/vue'
export default defineComponent({
  components: { ArrowLeftIcon, InfoIcon2, DateCreator },
  props: {
    forward_date: Number
  },
  setup(props) {
    const ionRouter = useIonRouter()
    const {
      toggleInterest,
      interest,
      goBack,
      percentage,
      lock_savings,
      toggleLockSavings,
      newAmount,
      deduction,
      deduction_percentage,
      make_public,
      toggleMakePublic,
      villager_fee,
      toggleVillagerFee,
      fee
    } = useInterestPage()
    const use_date = props.forward_date as any

    function popSwal() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Village created successfully'
      })
      ionRouter.navigate('/create_pots', 'forward', 'replace')
    }

    return {
      toggleInterest,
      interest,
      goBack,
      percentage,
      lock_savings,
      toggleLockSavings,
      use_date,
      newAmount,
      deduction,
      deduction_percentage,
      make_public,
      toggleMakePublic,
      villager_fee,
      toggleVillagerFee,
      fee,
      popSwal
    }
  }
})
