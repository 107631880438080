
import HomeCards from '@/components/HomePage/HomeCards.vue'
import TopBar from '@/components/HomePage/TopBar.vue'
import { IonContent, IonPage } from '@ionic/vue'
import useGeneralHomePage from '../composables/HomePage/general'

import { defineComponent, ref } from 'vue'
import HomeMiddleCard from '@/components/HomePage/HomeMiddleCard.vue'
import HomeThirdCard from '@/components/HomePage/HomeThirdCard.vue'
import FooterCard from '@/components/FooterCard.vue'
import CompleteVerification from '@/components/HomePage/CompleteVerification.vue'
import SideBar from '@/components/SideBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'
import useGeneralMiddleware from '@/composables/Middleware/home'
import useAccessToken from '@/composables/access_token'

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,
    TopBar,
    HomeCards,
    HomeMiddleCard,
    HomeThirdCard,
    FooterCard,
    CompleteVerification,
    SideBar,
    LoaderIcon
  },
  setup() {
    const { show_shadow, showShadow } = useGeneralHomePage()
    const { login_loader, user } = useGeneralMiddleware()
    const menu = ref(false)
    useAccessToken()

    function toggleMenu(val: boolean) {
      menu.value = val
    }

    if (isPlatform('android')) {
      StatusBar.setBackgroundColor({ color: '#F2F2F2' })
    }
    return { show_shadow, showShadow, menu, toggleMenu, login_loader, user }
  }
})
