
import useToggleSidebar from '@/composables/SideBar/toggle_sidebar'
// import ProfileIcon from './icons/ProfileIcon.vue'
import SettingsIcon from './icons/SettingsIcon.vue'
import InfoIcon from './icons/InfoIcon.vue'
import SupportIcon from './icons/SupportIcon.vue'
import LogoutIcon from './icons/LogoutIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import { useIonRouter } from '@ionic/vue'
import { useLogin } from '@/store/login'

export default defineComponent({
  components: { /* ProfileIcon, */ SettingsIcon, InfoIcon, SupportIcon, LogoutIcon },
  setup() {
    const { toggleMenu } = useToggleSidebar()
    const ionRouter = useIonRouter()
    const login = useLogin()

    return { toggleMenu, ionRouter, login }
  }
})
