
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent, onMounted } from '@vue/runtime-core'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import PersonalDetails from '@/components/Settings/PersonalDetails.vue'
import useSettings from '@/composables/Settings/settings'
import SecurityDetails from '@/components/Settings/SecurityDetails.vue'
import EditPots from '@/components/Settings/EditPots/EditPots.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    HalfIcon,
    PersonalDetails,
    SecurityDetails,
    EditPots,
    StatusBarComponent
  },
  setup(props, context) {
    const ionRouter = useIonRouter()
    const { toggleBetweenPages, personal, security, edit_pots, loadTab } = useSettings()
    onMounted(() => {
      loadTab(context.attrs.page_id as any)
      if (isPlatform('android')) {
        StatusBar.setBackgroundColor({ color: '#F3E7D5' })
      }
    })
    return { ionRouter, toggleBetweenPages, personal, security, edit_pots, isPlatform }
  }
})
