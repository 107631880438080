<template>
  <div class="h-85-screen flex flex-col justify-between pt-4">
    <div>
      <div class="text-card-100 pb-4 font-medium flex justify-center">
        <info-icon-2 :color="'#ED6500'"></info-icon-2>
        <div class="pl-2">Simply tap on a pot to edit or delete</div>
      </div>
      <edit-personal-pots></edit-personal-pots>
      <edit-village-pots></edit-village-pots>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import InfoIcon2 from '../../icons/InfoIcon2.vue'
import EditPersonalPots from './EditPersonalPots.vue'
import EditVillagePots from './EditVillagePots.vue'

export default defineComponent({
  components: { InfoIcon2, EditPersonalPots, EditVillagePots },
  setup() {
    return {}
  }
})
</script>

<style></style>
