import { ref } from "vue"

export default () => {
    const bvn = ref('09887654333')
    const bank_account = ref('0455126662')

    const fullname = ref('Ndidiamaka Ajayi')
    const email = ref('ndajayi@gmail.com')
    const phone_number = ref('08100623594')
    return { bvn, bank_account, fullname, email, phone_number }
}