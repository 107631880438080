<template>
  <svg :class="[width, height]" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M176.276 35.3662C176.779 35.9592 177.279 36.5542 177.768 37.1613L100 100V0C44.7696 0 0 44.7716 0 100C0 155.228 44.7696 200 100 200C155.226 200 200 155.228 200 100C200 75.3497 191.063 52.7981 176.276 35.3662Z"
      fill="#ED6500"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M166 28.8695C153.27 11.8743 133.813 1 112 1V76L166 28.8695Z"
      fill="#FCBC5F"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-48',
      type: String
    },
    height: {
      default: 'h-48',
      type: String
    }
  }
}
</script>

<style></style>
