import Swal from "sweetalert2"
import { getCurrentInstance, ref } from "vue"

export default () => {
    const { emit } = getCurrentInstance() as any
    const index_0 = ref('')
    const index_1 = ref('')
    const index_2 = ref('')
    const index_3 = ref('')
    const index_4 = ref('')
    const index_5 = ref('')
    const loading = ref(false)

    function showRegister(val: boolean) {
        emit('show-register', val)
    }
    function getFocus(id: string, past_id = '') {
        document.getElementById(id)?.focus()
        if (past_id !== '') {
            document.getElementById(past_id)?.blur()
        }
    }
    function signInUser(confirmationResult: any) {
        loading.value = true
        const code = index_0.value.concat(index_1.value, index_2.value, index_3.value, index_4.value, index_5.value)
        confirmationResult.confirm(code).then((result: any) => {
            // User signed in successfully.
            const user = result.user;
            showRegister(true)
            loading.value = false
            console.log(user)
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'warning',
                title: 'Log in was successfully, please complete registration here.'
            })
            // ...
        }).catch((error: any) => {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log(error)
        });
    }

    return { showRegister, index_0, index_1, index_2, index_3, index_4, index_5, getFocus, signInUser, loading }
}