import { ref } from 'vue'

export default () => {
  const show_shadow = ref(false)

  function showShadow(val: boolean) {
    show_shadow.value = val
  }

  return { show_shadow, showShadow }
}
