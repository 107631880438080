
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import FindVillage from '@/components/VillagePot/List/FindVillage.vue'
import VillageListCards from '@/components/VillagePot/List/VillageListCards.vue'
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import { ref } from 'vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, ArrowLeftIcon, VillageListCards, FindVillage, StatusBarComponent },
  setup() {
    const village_lists = ref(true)
    const find_villages = ref(false)
    const ionRouter = useIonRouter()

    function togglePages(page_1: boolean, page_2: boolean) {
      village_lists.value = page_1
      find_villages.value = page_2
    }

    return { ionRouter, togglePages, village_lists, find_villages, isPlatform }
  }
})
