
import { useAddUserDetails } from "@/store/add_user_details"
import { useLogin } from "@/store/login"
import { useIonRouter } from "@ionic/vue"
import Swal from "sweetalert2"
import { computed, ref, watchEffect } from "vue"

interface userObjectFace {
    fullname: string,
    email: string,
    referral_code: string,
    access_token: {
        token: null,
        expires_at: null
    }
}

export default () => {
    const addUser = useAddUserDetails()
    const loggedIn = useLogin()
    const fullname = ref('')
    const email = ref('')
    const referral_code = ref('')
    const add_user_loader = computed(() => addUser.add_user_loader)
    const user = computed(() => loggedIn.user)
    const ionRouter = useIonRouter()

    const validator = () => {
        if (fullname.value !== '' && email.value !== '') {
            return true
        } else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'warning',
                title: 'Fullname and Email fields are required.'
            })
        }
    }

    const registerUser = () => {
        if (validator()) {
            const userObject: userObjectFace = {
                fullname: fullname.value,
                email: email.value,
                referral_code: referral_code.value,
                access_token: {
                    token: null,
                    expires_at: null
                }
            }
            addUser.addUserDetails(userObject)
        }
    }

    watchEffect(() => {
        if (user.value.email === '' || user.value.email === undefined) {
            //
        } else if (user.value.email !== '') {
            ionRouter.navigate('/home', 'forward', 'replace')
        }
    })

    return { fullname, email, referral_code, registerUser, add_user_loader, user }
}