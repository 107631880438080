import { getCurrentInstance, ref, watch } from "vue"

export default () => {
    const amount_page = ref(false)
    const pot = ref('')
    const { emit } = getCurrentInstance() as any

    function emitValues() {
        emit('page-values', { amount_page: amount_page.value, pot: pot.value })
    }

    watch(amount_page, () => {
        emitValues()
    })
    return { amount_page, pot, emitValues }
}