<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13L7 7L1 1" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-2',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#c4c4c4',
      type: String
    }
  }
}
</script>

<style></style>
