import { ref } from "vue"

export default () => {
    const pot = ref({
        pot_name: 'House Rent',
        target_amount: 1300000,
        frequency: 'Everyday',
        maturity_date: 'April 19th, 2022'
    })
    return { pot }
}