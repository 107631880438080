
import ArrowLeftIcon from '../icons/ArrowLeftIcon.vue'
import useAcctDetails from '@/composables/SendMoney/send_money_acct_details'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { ArrowLeftIcon },
  props: {
    transfer_amount: String
  },
  setup(props) {
    const amount = props.transfer_amount as any
    const { goBack, acct_number, bank_name, finish } = useAcctDetails()
    return { goBack, amount, acct_number, bank_name, finish }
  }
})
