import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-50 max-w-md mx-auto min-h-screen xs:shadow-2xl relative overflow-x-auto" }
const _hoisted_2 = { class: "px-2 xxs:px-5 pt-4" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_icon = _resolveComponent("arrow-left-icon")!
  const _component_create_card = _resolveComponent("create-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "animate__animated animate__fadeInLeft" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = () => _ctx.ionRouter.navigate('/home', 'forward', 'replace'))
                }, [
                  _createVNode(_component_arrow_left_icon, {
                    width: 'w-4',
                    color: '#474747'
                  })
                ])
              ])
            ]),
            _createVNode(_component_create_card)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}