
import { defineComponent } from '@vue/runtime-core'
import useVillagers from '@/composables/VillagePot/villagers'
export default defineComponent({
  components: {},
  setup() {
    const { villagers } = useVillagers()
    return { villagers }
  }
})
