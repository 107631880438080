
import MenuIcon from '../icons/MenuIcon.vue'
import NotificationIcon from '../icons/NotificationIcon.vue'
import useToggleSidebar from '@/composables/SideBar/toggle_sidebar'
import { useIonRouter } from '@ionic/vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default {
  components: { NotificationIcon, MenuIcon, StatusBarComponent },
  props: {
    show_shadow: Boolean
  },
  setup() {
    const { toggleMenu } = useToggleSidebar()

    const ionRouter = useIonRouter()

    return { toggleMenu, ionRouter, isPlatform }
  }
}
