
import PhoneIcon from '../icons/PhoneIcon.vue'
import usePhoneVerify from '@/composables/WelcomePage/phone_verification'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { PhoneIcon },
  setup() {
    const { verificationCode, phone, loading } = usePhoneVerify()

    return { verificationCode, phone, loading }
  }
})
