<template>
  <svg :class="[width, height]" viewBox="0 0 79 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M69.2001 2.06096C71.1619 -0.033817 74.4454 -0.136864 76.5338 1.83093C78.5918 3.77029 78.7212 6.99711 76.8468 9.09501L76.7628 9.18694L31.9574 57.0282L2.13998 34.3315C-0.142493 32.5941 -0.588673 29.3298 1.1435 27.0404C2.85111 24.7829 6.03876 24.3163 8.3157 25.9687L8.41287 26.0409L30.7897 43.0735L69.2001 2.06096Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-10',
      type: String
    },
    height: {
      default: 'h-10',
      type: String
    }
  }
}
</script>

<style></style>
