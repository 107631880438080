import { onMounted, ref, watch } from "vue"

export default () => {
  const villages = ref([
    {
      title: 'Bungalow in Lekki',
      total_members: 10,
    },
    {
      title: 'Duplex in Sangotedo',
      total_members: 40,
    },
    {
      title: '3 Bedroom Terrace',
      total_members: 50,
    },
    {
      title: 'Estate Transformer',
      total_members: 220,
    },
    {
      title: 'Bungalow in Ikorodu',
      total_members: 20,
    },
    {
      title: 'Duplex in Lekki',
      total_members: 80,
    },
    {
      title: 'Idowu Dabiri Transit',
      total_members: 100,
    },
    {
      title: 'Idowu Dabiri Transformer',
      total_members: 100,
    },
  ])
  const search_villages = ref('')
  const searched_villages = ref([]) as any

  watch(search_villages, (arg: string) => {
    if (arg != '') {
      searched_villages.value = []
      searchVillages(arg)
    } else if (arg == '') {
      searched_villages.value = villages.value
    }
  })

  function searchVillages(arg: string) {
    villages.value.forEach(village => {
      const village_title = village.title.toLowerCase()
      const lowercase_val = arg.toLowerCase()
      if (village_title.includes(lowercase_val)) {
        searched_villages.value.push(village)
      }
    })
  }

  onMounted(() => {
    searched_villages.value = villages.value
  })
  return { search_villages, searched_villages }
}