
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useListPersonalSavings from '@/composables/PersonalPot/list_personal_savings'

export default defineComponent({
  setup() {
    const ionRouter = useIonRouter()
    const { personal_pots } = useListPersonalSavings()
    return { ionRouter, personal_pots }
  }
})
