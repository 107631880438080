
import InflowIcon from '@/components/icons/InflowIcon.vue'
import OutflowIcon from '@/components/icons/OutflowIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import useVillageTransaction from '@/composables/VillagePot/transactions'
export default defineComponent({
  components: { InflowIcon, OutflowIcon },
  setup() {
    const { count } = useVillageTransaction()
    return { count }
  }
})
