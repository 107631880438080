
import SearchIcon from '@/components/icons/SearchIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import { useIonRouter } from '@ionic/vue'
import useFindVillages from '@/composables/VillagePot/find_vilage'
import HalfLogoIcon from '@/components/icons/HalfLogoIcon.vue'
import PeopleIcon from '@/components/icons/PeopleIcon.vue'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'
export default defineComponent({
  components: { SearchIcon, HalfLogoIcon, PeopleIcon, InfoIcon2 },
  setup() {
    const ionRouter = useIonRouter()
    const { search_villages, searched_villages } = useFindVillages()
    return { search_villages, searched_villages, ionRouter }
  }
})
