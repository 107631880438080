import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_icon = _resolveComponent("loader-icon")!
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_home_cards = _resolveComponent("home-cards")!
  const _component_complete_verification = _resolveComponent("complete-verification")!
  const _component_home_middle_card = _resolveComponent("home-middle-card")!
  const _component_home_third_card = _resolveComponent("home-third-card")!
  const _component_footer_card = _resolveComponent("footer-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "animate__animated animate__fadeInLeft" }, {
    default: _withCtx(() => [
      _createVNode(_component_loader_icon, { loading: _ctx.login_loader }, null, 8, ["loading"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["fixed inset-0 z-50 animate__animated", { animate__fadeInLeft: _ctx.menu, animate__fadeOutLeft: !_ctx.menu }])
              }, [
                _createVNode(_component_side_bar, { onToggleMenu: _ctx.toggleMenu }, null, 8, ["onToggleMenu"])
              ], 2), [
                [_vShow, _ctx.menu]
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_top_bar, {
              class: "max-w-md mx-auto",
              show_shadow: _ctx.show_shadow,
              onToggleMenu: _ctx.toggleMenu
            }, null, 8, ["show_shadow", "onToggleMenu"]),
            _createElementVNode("div", {
              class: "bg-main-500 min-h-screen pt-8 pb-6 text-gray-800 text-2xl max-w-md mx-auto xs:shadow-2xl",
              onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showShadow(true))),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showShadow(false)))
            }, [
              _createVNode(_component_home_cards),
              _createVNode(_component_complete_verification),
              _createVNode(_component_home_middle_card),
              _createVNode(_component_home_third_card)
            ], 32),
            _createVNode(_component_footer_card, { active: 'home' })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}