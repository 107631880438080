import { ref } from "vue"

export default () => {
    const hours = ref(19)
    const minutes = ref(1)
    const seconds = ref(59)

    function countDown() {
        if (seconds.value !== 0) {
            seconds.value = seconds.value - 1
        } else if (seconds.value === 0 && (minutes.value !== 0 || hours.value !== 0)) {
            seconds.value = 59
            if (minutes.value !== 0) {
                minutes.value = minutes.value - 1
            } else if (minutes.value === 0) {
                minutes.value = 59
                hours.value = hours.value - 1
            }
        }
    }
    function padLeadingZeros(num: number, size: number): string {
        let newNumber = num + ""
        while (newNumber.length < size) newNumber = '0' + newNumber
        return newNumber
    }
    return { countDown, hours, minutes, seconds, padLeadingZeros }
}