import Swal from "sweetalert2"
import { ref } from "vue"

export default () => {
    const balance = ref(15000)
    const transfer_amount = ref('')
    const icon_content = ref('') as any
    const title = ref('') as any
    const timer = ref(0)
    const next_page = ref(false)

    function checkBalance() {
        if (transfer_amount.value !== '') {
            if (parseFloat(transfer_amount.value) > balance.value) {
                icon_content.value = 'error'
                title.value = 'Your current balance of &#8358;' + balance.value.toLocaleString() + ' is insufficient for this transaction.'
                timer.value = 10000
                finish()
            } else if (parseFloat(transfer_amount.value) <= balance.value) {
                next_page.value = true
            }
        } else {
            icon_content.value = 'info'
            title.value = 'Input amount to send'
            timer.value = 4000
            finish()
        }
    }

    function finish() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: timer.value,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon_content.value,
            title: title.value
        })
    }

    return { checkBalance, balance, transfer_amount, next_page }
}