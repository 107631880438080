export default () => {
    const addToDate = (params: any) => {
        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()
        const hour = d.getHours()
        const minutes = d.getMinutes()
        const newDate = new Date(year + params.year, month + params.month, day + params.day, hour + params.hour, minutes + params.minutes).getTime()
        return newDate
    }

    const subtractFromDate = (params: any) => {
        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()
        const hour = d.getHours()
        const minutes = d.getMinutes()
        const newDate = new Date(year - params.year, month - params.month, day - params.day, hour - params.hour, minutes - params.minutes).getTime()
        return newDate
    }
    return { addToDate, subtractFromDate }
}