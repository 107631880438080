<template>
  <div class="h-85-screen flex flex-col justify-between pt-4">
    <div>
      <div class="text-card-100 pb-4 font-medium flex justify-center">
        <info-icon-2 :color="'#ED6500'"></info-icon-2>
        <div class="pl-2">Tap a row to edit</div>
      </div>
      <div>
        <div>
          <small class="animate__animated animate__bounceIn ml-2" v-show="bvn !== ''">BVN</small>
          <input
            type="text"
            v-model="bvn"
            placeholder="BVN"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': bank_account === '', 'mt-2': bank_account !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="bank_account !== ''">Bank Account</small>
          <input
            type="text"
            v-model="bank_account"
            placeholder="Bank Account"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
      </div>
      <div class="pt-5">
        <div class="text-xl font-medium">Next of kin details</div>
        <div :class="{ 'mt-4': fullname === '', 'mt-2': fullname !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="fullname !== ''">Full Name</small>
          <input
            type="text"
            v-model="fullname"
            placeholder="Full Name"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': email === '', 'mt-2': email !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="email !== ''">Email</small>
          <input
            type="email"
            v-model="email"
            placeholder="Email"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
        <div :class="{ 'mt-4': phone_number === '', 'mt-2': phone_number !== '' }">
          <small class="animate__animated animate__bounceIn ml-2" v-show="phone_number !== ''">Phone Number</small>
          <input
            type="text"
            v-model="phone_number"
            placeholder="Phone Number"
            class="w-full rounded-xl px-5 py-3 bg-card-360 focus:outline-none focus:shadow-inner font-bold"
          />
        </div>
      </div>
    </div>
    <div class="w-full py-3">
      <div class="bg-card-150 rounded-3xl p-2 hover:shadow-lg w-5/12 mx-auto">
        <div class="bg-card-100 rounded-2xl py-3">
          <div class="flex justify-between items-center">
            <div class="text-lg text-gray-50 font-semibold text-center w-full cursor-pointer">Update</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon2 from '../icons/InfoIcon2.vue'
import useSecurity from '@/composables/Settings/security'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { InfoIcon2 },
  setup() {
    const { bvn, bank_account, fullname, email, phone_number } = useSecurity()

    return { bvn, bank_account, fullname, email, phone_number }
  }
})
</script>

<style></style>
