import { defineStore } from 'pinia'
// import router from '@/router'
import fb from '@/services/firebase'
import Swal from 'sweetalert2'
import UserFace from "@/types/user.type"

export const useLogin = defineStore('login', {
    state: () => ({
        user: {} as UserFace,
        token: '',
        loggedIn: false,
        login_loader: false
    }),
    actions: {
        currentlySignedInUser() {
            this.user = {} as UserFace
            this.login_loader = true
            fb.auth.onAuthStateChanged((userAuth: any) => {
                if (userAuth) {
                    this.loggedIn = true
                    const uid = userAuth.uid;
                    const phone = userAuth.phoneNumber
                    this.getUserDetails(uid, phone)
                } else {
                    this.user = {} as UserFace
                    this.login_loader = false
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                        didOpen: toast => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'warning',
                        title: 'You are currently signed out, please sign in to continue.'
                    })
                    // if (router.currentRoute.value.name !== 'welcome') {
                    //     router.push('/welcome')
                    // }
                }
            });
        },
        getUserDetails(uid: string, phone: string) {
            let userObject = {} as UserFace
            fb.db.collection('users').doc(uid).onSnapshot((userDetails: any) => {
                if (userDetails.exists) {
                    userObject = {
                        email: userDetails.data().email,
                        fullname: userDetails.data().fullname,
                        phone: phone,
                        uid: uid,
                        referral_code: userDetails.data().referral_code,
                        access_token: userDetails.data().access_token
                    }
                    this.user = userObject
                    this.token = userDetails.data().access_token.token
                    this.login_loader = false
                } else {
                    userObject = {
                        email: '',
                        fullname: '',
                        phone: phone,
                        uid: uid,
                        access_token: {
                            token: null,
                            expires_at: null
                        },
                        referral_code: ''
                    }
                    this.user = userObject
                    this.login_loader = false
                }
            })
        },
        logout() {
            fb.auth.signOut().then(() => {
                // Sign-out successful.
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                    didOpen: toast => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Logged Out successfully.'
                })
                window.location.reload()
            }).catch((error) => {
                console.log(error)
                // An error happened.
            });
        }
    }
})