<template>
  <svg :class="[width, height]" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.17231 20.63C8.01639 20.6203 7.82929 20.6203 7.65778 20.63C3.9469 20.5522 1.00006 18.6549 1.00006 16.3199C0.984467 13.9362 4.08723 12 7.90725 12C11.7272 12 14.83 13.9362 14.83 16.3199C14.83 18.6549 11.8676 20.5522 8.17231 20.63Z"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.1606 9.87C8.0606 9.86 7.9406 9.86 7.8306 9.87C5.45058 9.79 3.56058 7.84 3.56058 5.44C3.55058 2.99 5.54058 1 7.9906 1C10.4406 1 12.4306 2.99 12.4306 5.44C12.4306 7.84 10.5306 9.79 8.1606 9.87Z"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
