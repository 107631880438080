<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.9792 24.0003V14.2178C26.9793 13.5372 26.835 12.8638 26.5551 12.2387C26.2752 11.6137 25.8657 11.0502 25.3516 10.5828L15.7744 1.87281C15.292 1.43397 14.6534 1.18945 13.9896 1.18945C13.3258 1.18945 12.6872 1.43397 12.2048 1.87281L2.6276 10.5828C2.1135 11.0502 1.70397 11.6137 1.42411 12.2387C1.14424 12.8638 0.999925 13.5372 1 14.2178V24.0003C1 24.6634 1.27371 25.2992 1.76091 25.7681C2.24812 26.2369 2.90891 26.5003 3.59792 26.5003H24.3813C25.0703 26.5003 25.7311 26.2369 26.2183 25.7681C26.7055 25.2992 26.9792 24.6634 26.9792 24.0003Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-8',
      type: String
    },
    height: {
      default: 'h-7',
      type: String
    },
    color: {
      default: '#C4C4C4',
      type: String
    }
  }
}
</script>

<style></style>
