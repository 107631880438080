<template>
  <svg :class="[width, height]" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.5 2.25C32.0967 2.25 32.669 2.48705 33.091 2.90901C33.5129 3.33097 33.75 3.90326 33.75 4.5V31.5C33.75 32.0967 33.5129 32.669 33.091 33.091C32.669 33.5129 32.0967 33.75 31.5 33.75H4.5C3.90326 33.75 3.33097 33.5129 2.90901 33.091C2.48705 32.669 2.25 32.0967 2.25 31.5V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25H31.5ZM4.5 0C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5L0 31.5C0 32.6935 0.474106 33.8381 1.31802 34.682C2.16193 35.5259 3.30653 36 4.5 36H31.5C32.6935 36 33.8381 35.5259 34.682 34.682C35.5259 33.8381 36 32.6935 36 31.5V4.5C36 3.30653 35.5259 2.16193 34.682 1.31802C33.8381 0.474106 32.6935 0 31.5 0L4.5 0Z"
      :fill="color"
    />
    <path
      d="M20.0924 14.823L14.9399 15.4688L14.7554 16.3238L15.7679 16.5105C16.4294 16.668 16.5599 16.9065 16.4159 17.5658L14.7554 25.3687C14.3189 27.387 14.9916 28.3365 16.5734 28.3365C17.7996 28.3365 19.2239 27.7695 19.8696 26.991L20.0676 26.055C19.6176 26.451 18.9606 26.6085 18.5241 26.6085C17.9054 26.6085 17.6804 26.1742 17.8401 25.4093L20.0924 14.823ZM20.2499 10.125C20.2499 10.7217 20.0128 11.294 19.5909 11.716C19.1689 12.1379 18.5966 12.375 17.9999 12.375C17.4032 12.375 16.8309 12.1379 16.4089 11.716C15.9869 11.294 15.7499 10.7217 15.7499 10.125C15.7499 9.52826 15.9869 8.95597 16.4089 8.53401C16.8309 8.11205 17.4032 7.875 17.9999 7.875C18.5966 7.875 19.1689 8.11205 19.5909 8.53401C20.0128 8.95597 20.2499 9.52826 20.2499 10.125Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-6',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
