import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "max-w-md mx-auto h-screen xs:shadow-2xl w-screen relative overflow-x-auto" }
const _hoisted_2 = { class: "fixed h-screen max-w-md w-full bg-card-100 overflow-y-auto z-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_icon = _resolveComponent("arrow-left-icon")!
  const _component_status_bar_component = _resolveComponent("status-bar-component")!
  const _component_calendar_icon = _resolveComponent("calendar-icon")!
  const _component_personal_pots_graph = _resolveComponent("personal-pots-graph")!
  const _component_personal_pot_details = _resolveComponent("personal-pot-details")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "animate__animated animate__fadeInLeft" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["absolute left-5 cursor-pointer z-40", { 'top-3': !_ctx.isPlatform('iphone'), 'top-10': _ctx.isPlatform('iphone') }]),
              onClick: _cache[0] || (_cache[0] = () => _ctx.ionRouter.navigate('/list_personal_pots', 'forward', 'replace'))
            }, [
              _createVNode(_component_arrow_left_icon, {
                width: 'w-4 filter drop-shadow',
                color: '#c3c3c3'
              })
            ], 2),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.isPlatform('iphone'))
                ? (_openBlock(), _createBlock(_component_status_bar_component, {
                    key: 0,
                    height: 'h-7',
                    color: 'bg-transparent'
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["absolute right-5 cursor-pointer z-40", { 'top-3': !_ctx.isPlatform('iphone'), 'top-10': _ctx.isPlatform('iphone') }])
              }, [
                _createVNode(_component_calendar_icon)
              ], 2),
              _createVNode(_component_personal_pots_graph),
              _createVNode(_component_personal_pot_details)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}