
import TopBar from '@/components/HomePage/TopBar.vue'
import { IonContent, IonPage } from '@ionic/vue'
import useGeneralHomePage from '../composables/HomePage/general'

import { defineComponent, ref } from 'vue'
import VillagePots from '@/components/PotsPage/VillagePots.vue'
import PersonalPots from '@/components/PotsPage/PersonalPots.vue'
import SideBar from '@/components/SideBar.vue'
import FooterCard from '@/components/FooterCard.vue'

export default defineComponent({
  name: 'CreatePotsPage',
  components: {
    IonContent,
    IonPage,
    TopBar,
    VillagePots,
    PersonalPots,
    SideBar,
    FooterCard
  },
  setup() {
    const { show_shadow, showShadow } = useGeneralHomePage()
    const menu = ref(false)

    function toggleMenu(val: boolean) {
      menu.value = val
    }

    return { show_shadow, showShadow, menu, toggleMenu }
  }
})
