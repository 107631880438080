<template>
  <div>
    <div class="rounded-3xl px-2 pb-2 bg-card-150 shadow-lg">
      <div class="text-center text-sm py-4">Add Money to My Village Square Account</div>
      <div class="bg-card-100 rounded-3xl px-4">
        <div class="text-gray-50 text-sm py-5 flex justify-between">
          <div>Select one option</div>
          <div class="pr-2">Next</div>
        </div>
        <div class="pb-5 flex justify-between">
          <div class="w-3/4">
            <select
              v-model="add_money_type"
              class="px-5 py-3 rounded-3xl text-sm w-full bg-gray-50 font-semibold focus:outline-none"
            >
              <option value="transfer">Bank Transfer (Free)</option>
              <option value="paystack">Card, USSD or Bank</option>
            </select>
          </div>
          <div
            class="rounded-full bg-gray-50 shadow-md w-10 h-10 flex items-center text-center cursor-pointer"
            @click="addMoney"
          >
            <arrow-right-icon :color="'#ED6500'" :width="'w-2 mx-auto'"></arrow-right-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from '../icons/ArrowRightIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { ref } from '@vue/reactivity'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: { ArrowRightIcon },
  setup() {
    const ionRouter = useIonRouter()
    const add_money_type = ref('transfer')

    function addMoney() {
      if (add_money_type.value === 'transfer') {
        ionRouter.navigate('/bank_transfer', 'forward', 'replace')
      } else if (add_money_type.value === 'paystack') {
        ionRouter.navigate('/with_paystack', 'forward', 'replace')
      }
    }
    return { add_money_type, addMoney }
  }
})
</script>

<style></style>
