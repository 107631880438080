import { ref } from "vue"

export default () => {
    const negative_margin = ref(0)
    const slide = ref(0)

    function nextSlide(width: number) {
      if (slide.value == 0) {
        slide.value = 1
        negative_margin.value = width
      } else if (slide.value == 1) {
        slide.value = 2
        negative_margin.value = width * 2
      }
    }

    function switchSlide(width: number) {
      if (slide.value == 0) {
        slide.value = 1
        negative_margin.value = width
      } else if (slide.value == 1) {
        slide.value = 2
        negative_margin.value = width * 2
      } else if (slide.value == 2) {
        slide.value = 3
        negative_margin.value = width * 3
      } else if (slide.value == 3) {
        slide.value = 0
        negative_margin.value = 0
      }
    }
    return { nextSlide, switchSlide, negative_margin, slide }
}