import { ref } from "vue"

export default () => {
    const announcements = ref([
        {
            title: 'The five Secrets of Saving Money for Holidays in Nairobi Kenya goes viral in the single twist of events following.',
            content: 'After climbing seven mountains and crossing seven seas, the expert is here to reveal this golden secret that will blow ... After climbing seven',
            vendor: 'Airtel NG',
            created: '9 hours ago',
            likes: '510k'
        },
        {
            title: 'Grab this Holiday Package. Proudly sponsored by Jumia Travels. Only 10 slots available.',
            content: 'After climbing seven mountains and crossing seven seas, the expert is here to reveal this golden secret that will blow ... After climbing seven',
            vendor: 'MTN Nigeria',
            created: '6 days ago',
            likes: '250'
        },
        {
            title: 'HOW TO KNOW IF A SHEEP WILL ATTACK YOU.',
            content: 'No matter what you were doing once a run begins to move away from you, watch out; he is getting ready to headbutt you. When you see a ram charging towards you, he is definitely coming to get you.',
            vendor: 'Airtel NG',
            created: '7 days ago',
            likes: '5.10k'
        }
    ])
    return { announcements }
}