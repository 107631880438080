
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent, onMounted } from '@vue/runtime-core'
import MenuPotIcon from '@/components/icons/MenuPotIcon.vue'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'
import PersonalListCard from '@/components/PersonalPot/List/PersonalListCard.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: { IonContent, IonPage, ArrowLeftIcon, MenuPotIcon, InfoIcon2, PersonalListCard, StatusBarComponent },
  setup() {
    const ionRouter = useIonRouter()

    onMounted(() => {
      if (isPlatform('android')) {
        StatusBar.setBackgroundColor({ color: '#F2F2F2' })
      }
    })
    return { ionRouter, isPlatform }
  }
})
