<template>
  <svg :class="[width, height]" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4901 0.300818C15.3949 0.20546 15.2819 0.129807 15.1575 0.0781882C15.0331 0.0265698 14.8997 0 14.765 0C14.6303 0 14.497 0.0265698 14.3726 0.0781882C14.2481 0.129807 14.1351 0.20546 14.04 0.300818L2.84011 11.4924V5.74229C2.84011 5.17654 2.37731 4.71366 1.81166 4.71366C1.24601 4.71366 0.783203 5.17654 0.783203 5.74229V13.9714C0.783203 14.5371 1.24601 15 1.81166 15H10.0393C10.6049 15 11.0677 14.5371 11.0677 13.9714C11.0677 13.4056 10.6049 12.9427 10.0393 12.9427H4.29023L15.4901 1.74091C15.8809 1.35003 15.8809 0.691699 15.4901 0.300818Z"
      fill="#3F6900"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
