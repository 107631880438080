<template>
  <svg :class="[width, height]" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.72611 0.00186306C8.85811 -0.0155255 8.17753 0.0888061 7.68435 0.314858C7.05309 0.593076 6.50073 1.21907 6.02728 2.19283C6.00755 2.14066 5.9681 2.0798 5.90892 2.01025C5.69192 1.66247 5.52424 1.41034 5.40588 1.25384C5.28751 1.09735 5.12476 0.923459 4.91763 0.732184C4.7105 0.54091 4.4787 0.393107 4.22225 0.288775C3.63044 0.027946 2.9104 -0.0503027 2.06214 0.0540289C0.917966 0.297469 0.25711 0.975625 0.0795659 2.0885C-0.137432 2.80143 0.0894294 3.6013 0.76015 4.48812C1.2336 5.1315 1.50978 5.50536 1.58869 5.60969C1.72678 5.78357 2.07693 6.19221 2.63915 6.83558C3.20138 7.47896 3.58112 7.93107 3.77839 8.1919C4.74502 9.53082 5.34669 10.4611 5.58342 10.9828C5.60315 11.0175 5.67712 11.1871 5.80535 11.4914C5.93358 11.7957 6.00755 11.9652 6.02728 12C6.42182 10.4698 7.58572 8.69617 9.51897 6.67909C11.7679 4.40118 12.4879 2.61885 11.6791 1.33209C11.3437 0.671324 10.6927 0.227915 9.72611 0.00186306Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-3',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#979797',
      type: String
    }
  }
}
</script>

<style></style>
