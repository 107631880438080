
import HalfIcon from '../icons/HalfIcon.vue'
import PeopleIcon from '../icons/PeopleIcon.vue'
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: { HalfIcon, PeopleIcon },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
