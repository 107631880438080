import { useLogin } from './login'
import { defineStore } from 'pinia'
import fb from '@/services/firebase'

export const useAddUserDetails = defineStore('add_user_details', {
    state: () => ({
        add_user_loader: false
    }),
    actions: {
        addUserDetails(payload: any) {
            this.add_user_loader = true
            fb.auth.onAuthStateChanged((userAuth: any) => {
                if (userAuth) {
                    fb.db.collection('users').doc(userAuth.uid).set(payload).then(() => {
                        const login = useLogin()
                        login.currentlySignedInUser()
                        this.add_user_loader = false
                        setTimeout(() => {
                            window.location.href = '/home'
                        }, 1000);
                    })
                }
            })
        },
        addAccessToken(payload: any) {
            const uid = payload.uid
            delete payload.uid
            fb.db.collection('users').doc(uid).update({ access_token: payload })
        }
    }
})