<template>
  <div class="pt-14 pb-7 text-gray-800 h-50-screen">
    <div class="">
      <div class="text-center text-gray-50">
        <div class="text-sm">Virtual Acct Balance</div>
        <div class="text-3xl font-bold pt-2">&#8358;343,578.00</div>
      </div>
      <div class="w-full">
        <div id="curve_chart" class="w-full h-auto"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/runtime-core'

export default defineComponent({
  components: {},
  setup() {
    const chartData = ref([
      ['Days', 'Inflow', 'Outflow'],
      ['1st', 100, 420],
      ['2nd', 170, 155],
      ['3rd', 190, 192],
      ['4th', 210, 182],
      ['5th', 130, 1110],
      ['6th', 330, 345],
      ['7th', 230, 230],
      ['8th', 230, 280],
      ['9th', 430, 450],
      ['10th', 640, 540],
      ['11th', 730, 645],
      ['12th', 510, 550],
      ['13th', 100, 120],
      ['14th', 130, 132],
      ['15th', 430, 454],
      ['16th', 230, 232],
      ['17th', 630, 268],
      ['18th', 830, 44],
      ['19th', 450, 98],
      ['20th', 500, 152],
      ['21st', 330, 482],
      ['22nd', 430, 482],
      ['23rd', 230, 321],
      ['24th', 130, 134],
      ['25th', 630, 644],
      ['26th', 450, 560]
    ])
    const chartOptions = ref({
      curveType: 'function',
      legend: { position: 'bottom' },
      colors: ['#ffffff', '#474747'],
      backgroundColor: { fill: 'transparent' },
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      }
    })

    function loadChart() {
      // eslint-disable-next-line no-undef
      google.charts.load('current', { packages: ['corechart'] })
      // eslint-disable-next-line no-undef
      google.charts.setOnLoadCallback(drawChart)

      function drawChart() {
        // eslint-disable-next-line no-undef
        var data = google.visualization.arrayToDataTable(chartData.value)

        var options = chartOptions.value

        // eslint-disable-next-line no-undef
        var chart = new google.visualization.LineChart(document.getElementById('curve_chart'))

        chart.draw(data, options)
      }
    }

    onMounted(() => {
      setTimeout(() => {
        loadChart()
      }, 3000)
    })

    return {}
  }
})
</script>

<style></style>
