
import useVerificationCode from '@/composables/WelcomePage/verification_code'
import { ref } from '@vue/reactivity'
import { defineComponent, onMounted } from '@vue/runtime-core'
export default defineComponent({
  components: {},
  setup(props, ctx) {
    const { showRegister, index_0, index_1, index_2, index_3, index_4, index_5, getFocus, signInUser, loading } =
      useVerificationCode()
    let time = ref(10)

    function countDown() {
      setInterval(() => {
        if (time.value != 0) {
          time.value = time.value - 1
        }
      }, 1000)
    }

    function signIn() {
      signInUser(ctx.attrs.confirmation)
    }

    onMounted(() => {
      time.value = 60
      countDown()
    })

    return { showRegister, time, index_0, index_1, index_2, index_3, index_4, index_5, getFocus, signIn, loading }
  }
})
