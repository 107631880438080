
import useCardList from '@/composables/Cards/card_list'
import TransactionCardIcon from '@/components/icons/TransactionCardIcon.vue'
import MasterCard from '@/components/icons/MasterCard.vue'
import PotIcon from '@/components/icons/PotIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import { useIonRouter } from '@ionic/vue'
export default {
  components: { TransactionCardIcon, MasterCard, PotIcon, CheckIcon },
  setup() {
    const ionRouter = useIonRouter()
    const { cards, selectCard, cardDetails, selected_card, selected_card_index, disableCard, enableCard } =
      useCardList()

    return { cards, selectCard, cardDetails, selected_card, selected_card_index, disableCard, enableCard, ionRouter }
  }
}
