
import PersonWithBookIcon from '@/components/icons/PersonWithBookIcon.vue'
import PersonWithPhoneIcon from '@/components/icons/PersonWithPhoneIcon.vue'
import PieChartIcon from '@/components/icons/PieChartIcon.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: { PieChartIcon, PersonWithBookIcon, PersonWithPhoneIcon },
  setup() {
    //
  }
})
