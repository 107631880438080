import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "text-sm font-medium" }
const _hoisted_3 = { class: "text-xs font-light pt-4" }
const _hoisted_4 = { class: "pt-4 flex justify-between text-xs font-light" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "pl-1" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_speaker_icon = _resolveComponent("speaker-icon")!
  const _component_like_icon = _resolveComponent("like-icon")!
  const _component_share_icon = _resolveComponent("share-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.announcements, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["bg-card-105 px-6 pt-6 pb-5 rounded-2xl shadow-md border-t", {
        'bg-card-105 border-card-105': index % 3 == 0,
        'bg-card-500 border-card-500': index % 3 == 1,
        'bg-card-600 border-card-600': index % 3 == 2,
        'mt-4': index !== 0
      }]),
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.content), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_speaker_icon),
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.vendor), 1)
          ]),
          _createElementVNode("div", null, _toDisplayString(item.created), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, _toDisplayString(item.likes), 1),
            _createElementVNode("div", _hoisted_8, [
              (index % 2 == 0)
                ? (_openBlock(), _createBlock(_component_like_icon, {
                    key: 0,
                    color: '#ed6500'
                  }))
                : (_openBlock(), _createBlock(_component_like_icon, { key: 1 }))
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_share_icon)
          ])
        ])
      ], 2))
    }), 128))
  ]))
}