import { ref } from "vue"

export default () => {
  const village_pots = ref([
    {
      type: 'Housing',
      pots: [
        {
          title: 'Bungalow in Lekki',
          total_members: 100,
        },
        {
          title: 'Duplex in Sangotedo',
          total_members: 100,
        },
        {
          title: '3 Bedroom Terrace',
          total_members: 100,
        },
        {
          title: 'Estate Transformer',
          total_members: 100,
        },
      ]
    },
    {
      type: 'Travel & Holidays ',
      pots: [
        {
          title: 'Full body spa',
          total_members: 100,
        },
        {
          title: 'Nails',
          total_members: 100,
        },
        {
          title: 'Body massage',
          total_members: 100,
        },
        {
          title: 'Estate Festival',
          total_members: 100,
        },
      ]
    },
  ])


  return { village_pots }
}