import { ref } from "vue"

export default () => {
    const amount_page = ref(false)
    const pot = ref('')

    function toggleAmountPage() {
        if (amount_page.value) {
            amount_page.value = false
        } else {
            amount_page.value = true
        }
    }

    function acceptPageValues(val: any) {
        amount_page.value = val.amount_page
        pot.value = val.pot
    }

    return { amount_page, pot, toggleAmountPage, acceptPageValues }
}