<template>
  <svg :class="[width, height]" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :class="[width, height]" rx="20" fill="white" fill-opacity="0.12" />
    <path
      d="M24.6667 14.666H15.3333C14.597 14.666 14 15.263 14 15.9993V25.3327C14 26.0691 14.597 26.666 15.3333 26.666H24.6667C25.403 26.666 26 26.0691 26 25.3327V15.9993C26 15.263 25.403 14.666 24.6667 14.666Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M22.666 13.334V16.0007" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.334 13.334V16.0007" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 18.666H26" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-10',
      type: String
    },
    height: {
      default: 'h-10',
      type: String
    }
  }
}
</script>

<style></style>
