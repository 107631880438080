<template>
  <svg
    class="fill-current"
    :class="[width, height, color]"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="15.25" x2="17" y2="15.25" :stroke="color" stroke-width="1.5" />
    <line y1="8.25" x2="11" y2="8.25" :stroke="color" stroke-width="1.5" />
    <line y1="1.25" x2="17" y2="1.25" :stroke="color" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-6',
      type: String
    },
    height: {
      default: 'h-6',
      type: String
    },
    color: {
      default: 'text-gray-700',
      type: String
    }
  }
}
</script>

<style></style>
