<template>
  <svg :class="[width, height]" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.53436 2.6612C3.31443 5.10151 2.09381 7.54112 0.874357 9.98073C0.763497 10.2027 0.926857 10.5917 1.16358 10.6698C3.75811 11.5093 6.35498 12.3484 8.95186 13.1891C9.19139 13.2667 9.3367 12.9791 9.2099 12.6763C8.52272 11.0368 7.83365 9.39268 7.146 7.75487C6.45811 6.11425 5.77022 4.47362 5.08186 2.833C4.95459 2.53089 4.64779 2.43222 4.53553 2.66073"
      fill="#E48800"
    />
    <path
      d="M4.56806 2.56911C3.53493 4.63607 2.50063 6.70114 1.46938 8.76693C1.37587 8.95466 1.51368 9.28396 1.71384 9.34958C3.91063 10.0618 6.1086 10.7722 8.30681 11.4831C8.50977 11.5485 8.63352 11.305 8.52618 11.0486C7.94376 9.6606 7.3611 8.27052 6.77962 6.88302C6.19649 5.49435 5.61384 4.10474 5.03165 2.7163C4.92407 2.45966 4.66392 2.37763 4.56876 2.56911"
      fill="#FFA622"
    />
    <path
      d="M0.00873144 8.2831C0.367091 9.13763 0.724747 9.99122 1.08381 10.8458C1.31162 11.3902 1.53967 11.9328 1.76725 12.4763C1.78201 12.511 1.80967 12.5335 1.82865 12.5253C2.30209 12.3265 2.77553 12.1285 3.24897 11.9293L4.06201 11.5885C4.081 11.5808 4.08451 11.5456 4.07045 11.51C3.71139 10.6554 3.35373 9.80208 2.9949 8.94755C2.76733 8.40403 2.53951 7.86052 2.31147 7.31724C2.30563 7.30183 2.29614 7.28808 2.28381 7.27716V7.27669L2.28287 7.27575C2.27303 7.26778 2.26225 7.26427 2.2524 7.26825L0.832091 7.86403C0.561153 7.97724 0.290216 8.09138 0.0190439 8.20528C-0.000174813 8.21278 -0.00369044 8.2477 0.0110752 8.2831"
      fill="#ED6500"
    />
    <path
      d="M13.7328 8.75864C13.6711 8.61028 13.6093 8.46216 13.5474 8.31544C13.5378 8.29223 13.5387 8.27302 13.534 8.25122C13.5279 8.22778 13.52 8.20481 13.5195 8.17786C13.5187 8.13615 13.5263 8.09471 13.5418 8.05598C13.5436 8.04778 13.5427 8.04052 13.5455 8.03208C14.1392 6.52645 13.79 5.02645 12.5445 3.97903C11.8751 3.41653 11.5749 3.612 11.2533 2.84489C11.1221 2.53177 11.4364 2.26294 11.7338 2.35997C14.4558 3.25317 15.6856 6.18731 14.4158 8.75606C14.2768 9.03708 13.8643 9.07223 13.7328 8.75934"
      fill="#E48800"
    />
    <path
      d="M11.616 8.98402L11.4658 8.62542C11.4578 8.60691 11.4597 8.59074 11.4555 8.57293C11.4508 8.55371 11.4433 8.53636 11.4438 8.51457C11.4432 8.48077 11.4491 8.44718 11.4614 8.41566C11.4614 8.40909 11.4625 8.40258 11.4649 8.39644C11.9451 7.17769 11.6627 5.9648 10.6542 5.1173C10.1132 4.66238 9.87019 4.82105 9.61003 4.19996C9.50409 3.9466 9.75862 3.72886 9.99909 3.80878C12.2008 4.53043 13.196 6.90371 12.1673 8.98144C12.0562 9.20855 11.7225 9.23761 11.616 8.98472V8.98402ZM9.72019 9.09464C9.68316 9.00417 9.64425 8.91277 9.60698 8.82277C9.60112 8.80847 9.6023 8.79652 9.59925 8.78363C9.59644 8.76886 9.59058 8.75503 9.59058 8.73886C9.59003 8.7133 9.59457 8.68789 9.60394 8.6641C9.60534 8.65917 9.60441 8.65449 9.60675 8.64933C9.96933 7.72753 9.75628 6.80878 8.99292 6.16824C8.5837 5.82347 8.39972 5.94347 8.20237 5.47378C8.12198 5.28183 8.31464 5.11707 8.49651 5.17753C10.1636 5.72386 10.9167 7.52011 10.1395 9.09253C10.0551 9.26386 9.80152 9.28542 9.72136 9.09464"
      fill="#E48800"
    />
    <path
      d="M5.89473 8.23628C7.16434 11.2646 8.64747 13.5299 9.20645 13.2955C9.76567 13.0611 9.18958 10.4153 7.91879 7.3869C6.64848 4.35784 5.16629 2.09284 4.60708 2.32721C4.04809 2.56159 4.62442 5.20721 5.89497 8.23581"
      fill="#ED6500"
    />
    <path
      d="M6.21076 8.14418C7.30201 10.7434 8.50389 12.7194 8.896 12.5544C9.28787 12.3903 8.72115 10.1493 7.63107 7.54886C6.54029 4.9473 5.33889 2.9734 4.94654 3.13746C4.55443 3.30246 5.12115 5.54285 6.21076 8.14418Z"
      fill="#E48800"
    />
    <path
      d="M8.09592 7.35594C8.35139 7.96368 8.18053 8.615 7.71436 8.81071C7.24819 9.00641 6.66201 8.67219 6.40678 8.06399C6.15201 7.45696 6.32451 6.80633 6.79162 6.61063C7.25803 6.41516 7.84209 6.74821 8.09592 7.35594Z"
      fill="#3F6900"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
