<template>
  <svg :class="[width, height]" class="inline-block" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      :stroke="color"
      stroke-width="2"
    />
    <path d="M11 15.5V16M11 6V12V6Z" :stroke="color" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-5',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
