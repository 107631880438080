<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3645 0.166016H8.13589C6.88601 0.166016 5.87464 1.13993 5.87464 2.34352V8.45027L0.584887 13.4791C0.426391 13.63 0.318038 13.8229 0.273579 14.0333C0.229119 14.2436 0.250558 14.462 0.335173 14.6606C0.419789 14.8592 0.563767 15.0291 0.748835 15.1487C0.933903 15.2684 1.15172 15.3324 1.37464 15.3327V20.7493C1.37464 21.0367 1.49316 21.3122 1.70414 21.5154C1.91512 21.7185 2.20127 21.8327 2.49964 21.8327H20.4996C20.798 21.8327 21.0842 21.7185 21.2951 21.5154C21.5061 21.3122 21.6246 21.0367 21.6246 20.7493V2.34243C21.6246 1.13993 20.6144 0.166016 19.3645 0.166016ZM10.2869 14.3696V19.666H3.62464V13.634L6.98501 10.4393L10.2869 13.6925V14.3696ZM12.6246 7.74935H10.3746V5.58268H12.6246V7.74935ZM17.1246 16.416H14.8746V14.2493H17.1246V16.416ZM17.1246 12.0827H14.8746V9.91602H17.1246V12.0827ZM17.1246 7.74935H14.8746V5.58268H17.1246V7.74935Z"
      fill="#ED6500"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-8',
      type: String
    },
    height: {
      default: 'h-7',
      type: String
    },
    color: {
      default: '#ED6500',
      type: String
    }
  }
}
</script>

<style></style>
