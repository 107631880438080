<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 180 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M89.7275 56.4653L89.9738 109.855L180 -0.000419617H136.033L89.7275 56.4653Z"
      fill="#B8B8B8"
      fill-opacity="0.05"
    />
    <path
      d="M89.7275 56.4653L89.9738 109.855L180 -0.000419617H136.033L89.7275 56.4653Z"
      fill="#B8B8B8"
      fill-opacity="0.05"
    />
    <path
      d="M90.2715 56.6111L90.0252 110L0.000366211 0.146729H43.9663L90.2715 56.6111Z"
      fill="white"
      fill-opacity="0.05"
    />
    <path
      d="M90.2715 56.6111L90.0252 110L0.000366211 0.146729H43.9663L90.2715 56.6111Z"
      fill="white"
      fill-opacity="0.05"
    />
    <path d="M136.441 0.00299835H43.8164L90.1353 56.4688L136.441 0.00299835Z" fill="#0A0908" fill-opacity="0.05" />
    <path d="M136.441 0.00299835H43.8164L90.1353 56.4688L136.441 0.00299835Z" fill="#0A0908" fill-opacity="0.05" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-8',
      type: String
    },
    height: {
      default: 'h-8',
      type: String
    }
  }
}
</script>

<style></style>
