<template>
  <ion-page class="animate__animated animate__fadeInLeft">
    <ion-content :fullscreen="true">
      <div class="bg-gray-50 max-w-md mx-auto min-h-screen xs:shadow-2xl relative overflow-x-auto">
        <status-bar-component v-if="isPlatform('iphone')" :color="'bg-gray-50'"></status-bar-component>
        <div class="absolute right-0 -mt-5" :class="{ 'top-0': !isPlatform('iphone'), 'top-9': isPlatform('iphone') }">
          <half-icon :color="'#ED6500'" :width="'w-20'" :height="'h-20'"></half-icon>
        </div>
        <div
          class="absolute left-5 top-3 cursor-pointer"
          :class="{ 'top-3': !isPlatform('iphone'), 'top-12': isPlatform('iphone') }"
          @click="() => ionRouter.navigate('/add_money', 'forward', 'replace')"
        >
          <arrow-left-icon :width="'w-4'" :color="'#474747'"></arrow-left-icon>
        </div>
        <div class="px-4 xxs:px-6 py-14 flex flex-col justify-between h-screen min-h-screen">
          <div class="z-40">
            <div class="text-xl text-center font-bold">How much do you want to add to your Village Square account?</div>
            <div class="pt-5">
              <small class="animate__animated animate__bounceIn ml-2" v-show="transfer_amount !== ''"
                >Amount to send to Village Square Account<span class="font-semibold"
                  >: &#8358;{{ parseFloat(transfer_amount).toLocaleString() }}</span
                ></small
              >
              <input
                type="text"
                v-model="transfer_amount"
                placeholder="e.g 1,000,000 Naira"
                class="w-full rounded-xl px-5 py-3 bg-main-500 focus:outline-none focus:shadow-inner font-bold"
              />
            </div>
          </div>
          <div class="absolute bottom-0 left-0 z-0">
            <person-with-book-icon class="opacity-5"></person-with-book-icon>
          </div>
          <div class="bg-card-150 rounded-3xl p-2 hover:shadow-lg mt-3 relative" @click="finish">
            <div class="bg-card-100 rounded-2xl px-4 py-3">
              <div class="flex justify-between items-center">
                <div class="text-xl text-gray-50 font-semibold text-center w-full cursor-pointer">Finish</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import PersonWithBookIcon from '@/components/icons/PersonWithBookIcon.vue'
import useWithPaystack from '@/composables/WithPaystack/amount_to_paystack'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, HalfIcon, ArrowLeftIcon, PersonWithBookIcon, StatusBarComponent },
  setup() {
    const ionRouter = useIonRouter()
    const { finish, transfer_amount } = useWithPaystack()
    return { ionRouter, finish, transfer_amount, isPlatform }
  }
})
</script>

<style></style>
