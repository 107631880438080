
import { defineComponent } from 'vue'
import ShareIcon from '../icons/ShareIcon.vue'

export default defineComponent({
  components: { ShareIcon },
  setup() {
    //
  }
})
