
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import EditPotDetails from '@/components/Settings/EditPots/EditPotDetails.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, ArrowLeftIcon, HalfIcon, EditPotDetails, StatusBarComponent },
  setup() {
    const ionRouter = useIonRouter()

    return { ionRouter, isPlatform }
  }
})
