<template>
  <svg class="inline-block" :class="[width, height]" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9951 10.2672L20.0497 19.9746L40.0018 0.000650406H30.2577L19.9951 10.2672Z" fill="#ECC19A" />
    <path d="M20.0068 10.2929L19.9522 20L0.000484467 0.0265179H9.74445L20.0068 10.2929Z" fill="white" />
    <path d="M30.2262 0.000995636H9.69824L19.9637 10.2676L30.2262 0.000995636Z" fill="#979797" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-10',
      type: String
    },
    height: {
      default: 'h-6',
      type: String
    }
  }
}
</script>

<style></style>
