import { ref } from "vue"

export default () => {
    const personal = ref(true)
    const security = ref(false)
    const edit_pots = ref(false)

    function toggleBetweenPages(page_1: boolean, page_2: boolean, page_3: boolean) {
        personal.value = page_1
        security.value = page_2
        edit_pots.value = page_3
    }
    function loadTab(page_id: string) {
        if (page_id === '0') {
            toggleBetweenPages(true, false, false)
        } else if (page_id === '1') {
            toggleBetweenPages(false, true, false)
        } else if (page_id === '2') {
            toggleBetweenPages(false, false, true)
        }
    }
    return { toggleBetweenPages, personal, security, edit_pots, loadTab }
}