<template>
  <svg
    class="fill-current inline-block"
    :class="[width, height, color]"
    viewBox="0 0 32 34"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.875 19.1375C27.1125 20.5625 27.35 21.75 27.825 23.175L29.25 27.2125L28.0625 28.875H19.75C19.75 30.0625 19.275 31.25 18.325 32.2C17.375 33.15 16.1875 33.625 15 33.625C13.8125 33.625 12.3875 33.15 11.675 32.2C10.725 31.25 10.25 30.0625 10.25 28.875H1.9375L0.75 27.2125L2.175 23.175C2.65 21.275 3.125 19.375 3.125 17.475V12.25C3.125 10.5875 3.3625 8.925 4.075 7.5C4.7875 5.8375 5.7375 4.65 6.925 3.4625C8.1125 2.275 9.5375 1.5625 11.2 1.0875C12.3875 0.6125 13.8125 0.375 15 0.375C14.525 1.0875 14.05 2.0375 13.575 2.9875C13.1 2.9875 12.625 2.9875 11.9125 3.4625C10.725 3.7 9.5375 4.4125 8.5875 5.3625C7.6375 6.075 6.6875 7.2625 6.2125 8.45C5.7375 9.6375 5.5 10.825 5.5 12.25V17.475C5.5 19.6125 5.025 21.75 4.55 23.8875L3.6 26.5H26.4L25.45 23.8875C25.0344 22.6382 24.7992 21.2038 24.5879 19.9117L24.5 19.375C25.45 19.375 26.1625 19.375 26.875 19.1375ZM15 31.25C15.475 31.25 16.1875 31.0125 16.6625 30.5375C17.1375 30.0625 17.375 29.5875 17.375 28.875H12.625C12.625 29.5875 12.8625 30.0625 13.3375 30.5375C13.8125 31.0125 14.525 31.25 15 31.25ZM31.625 7.5C31.625 9.38967 30.8743 11.2019 29.5381 12.5381C28.2019 13.8743 26.3897 14.625 24.5 14.625C22.6103 14.625 20.7981 13.8743 19.4619 12.5381C18.1257 11.2019 17.375 9.38967 17.375 7.5C17.375 5.61033 18.1257 3.79806 19.4619 2.46186C20.7981 1.12567 22.6103 0.375 24.5 0.375C26.3897 0.375 28.2019 1.12567 29.5381 2.46186C30.8743 3.79806 31.625 5.61033 31.625 7.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-10',
      type: String
    },
    height: {
      default: 'h-10',
      type: String
    },
    color: {
      default: 'text-gray-800',
      type: String
    }
  }
}
</script>

<style></style>
