
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import { useLogin } from '@/store/login'
import { useAuthorizationCode } from '@/store/authorization_code'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const loginModule = useLogin()
    const authorizationCodeModule = useAuthorizationCode()
    onMounted(() => {
      loginModule.currentlySignedInUser()
      authorizationCodeModule.getAuthorizationCode()
    })
  }
});
