import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import OnboardPage from '../views/OnboardPage.vue'
import WelcomePage from '../views/WelcomePage.vue'
import AddMoneyPage from '../views/AddMoneyPage.vue'
import BankTransferPage from '../views/BankTransferPage.vue'
import WithPaystackPage from '../views/WithPaystackPage.vue'
import CreatePotsPage from '../views/CreatePotsPage.vue'
import CreatePersonalPotPage from '../views/CreatePersonalPotPage.vue'
import CreateVillagePotPage from '../views/CreateVillagePotPage.vue'
import SaveMoneyPage from '../views/SaveMoneyPage.vue'
import SendMoneyPage from '../views/SendMoneyPage.vue'
import ViewTransactionsPage from '../views/Transactions/ViewTransactionsPage.vue'
import ListPersonalPotsPage from '../views/MySavingsPots/Personal/ListPersonalPotsPage.vue'
import ViewPersonalPotsPage from '../views/MySavingsPots/Personal/ViewPersonalPotsPage.vue'
import ListVillagePotsPage from '../views/MySavingsPots/Village/ListVillagePotsPage.vue'
import ViewVillagePotsPage from '../views/MySavingsPots/Village/ViewVillagePotsPage.vue'
import AnnouncementsPage from '../views/Announcements/AnnouncementsPage.vue'
import VillageMarketDetailsPage from '../views/Announcements/VillageMarketDetailsPage.vue'
import SettingsPage from '../views/Settings/SettingsPage.vue'
import EditPotDetailsPage from '../views/Settings/EditPotDetailsPage.vue'
import CardsPage from '../views/Cards/CardsPage.vue'
import CreateCardPage from '../views/Cards/CreateCardPage.vue'
import ViewCardDetails from '../views/Cards/ViewCardDetailsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: OnboardPage
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: WelcomePage
  },
  {
    path: '/add_money',
    name: 'AddMoney',
    component: AddMoneyPage
  },
  {
    path: '/bank_transfer',
    name: 'BankTransfer',
    component: BankTransferPage
  },
  {
    path: '/with_paystack',
    name: 'WithPaystack',
    component: WithPaystackPage
  },
  {
    path: '/create_pots',
    name: 'CreatePots',
    component: CreatePotsPage
  },
  {
    path: '/create_personal_pot',
    name: 'CreatePersonalPot',
    component: CreatePersonalPotPage
  },
  {
    path: '/create_village_pot',
    name: 'CreateVillagePot',
    component: CreateVillagePotPage
  },
  {
    path: '/save_money',
    name: 'SaveMoney',
    component: SaveMoneyPage
  },
  {
    path: '/send_money',
    name: 'SendMoney',
    component: SendMoneyPage
  },
  {
    path: '/view_transactions',
    name: 'ViewTransactions',
    component: ViewTransactionsPage
  },
  {
    path: '/list_personal_pots',
    name: 'ListPersonalPots',
    component: ListPersonalPotsPage
  },
  {
    path: '/view_personal_pots',
    name: 'ViewPersonalPots',
    component: ViewPersonalPotsPage
  },
  {
    path: '/list_village_pots',
    name: 'ListVillagePots',
    component: ListVillagePotsPage
  },
  {
    path: '/view_village_pots',
    name: 'ViewVillagePots',
    component: ViewVillagePotsPage
  },
  {
    path: '/announcements/:page_id',
    name: 'Announcements',
    component: AnnouncementsPage,
    props: true
  },
  {
    path: '/village_market_details',
    name: 'VillageMarketDetails',
    component: VillageMarketDetailsPage
  },
  {
    path: '/settings/:page_id',
    name: 'Settings',
    component: SettingsPage,
    props: true
  },
  {
    path: '/settings/edit_pot',
    name: 'EditPotDetails',
    component: EditPotDetailsPage
  },
  {
    path: '/cards',
    name: 'Cards',
    component: CardsPage
  },
  {
    path: '/create_card',
    name: 'CreateCard',
    component: CreateCardPage
  },
  {
    path: '/view_card_details',
    name: 'ViewCardDetails',
    component: ViewCardDetails
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
