
import { defineComponent, onMounted } from '@vue/runtime-core'
import ArrowRightIcon from '../icons/ArrowRightIcon.vue'
import interswitch from '@/services/interswitch'

export default defineComponent({
  components: { ArrowRightIcon },
  setup() {
    const { bankCodes } = interswitch()
    onMounted(() => {
      setTimeout(() => {
        bankCodes().then((res: any) => {
          console.log(res)
        }).catch((err: any) => {
          console.log(err)
        })
      }, 2000)
    })
    return {}
  }
})
