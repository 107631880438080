<template>
  <div>
    <div class="text-card-100 py-4 font-medium flex justify-center mb-5" @click="selectTab('')">
      <info-icon-2 :color="'#ED6500'"></info-icon-2>
      <div class="pl-2">Simply tap on any row to copy</div>
    </div>
    <div class="">
      <div
        class="w-full rounded-md px-5 py-3 my-2 hover:shadow-md font-semibold"
        v-for="(card, index) in card_details"
        :key="index"
        @click="selectTab(index.toString())"
        :class="{ 'bg-gray-200': tab_index !== index.toString(), 'bg-card-100': tab_index === index.toString() }"
      >
        <div class="flex justify-between text-sm">
          <div
            :class="{ 'text-gray-50': tab_index === index.toString(), 'text-gray-700': tab_index !== index.toString() }"
          >
            {{ card.content }}
          </div>
          <div
            class="filter drop-shadow"
            :class="{ 'text-gray-50': tab_index === index.toString(), 'text-gray-400': tab_index !== index.toString() }"
          >
            {{ card.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-200 rounded-3xl p-2 hover:shadow-lg mx-4 mt-12">
      <div class="bg-ash-40 rounded-2xl px-4 py-3">
        <div class="flex justify-between items-center">
          <div class="text-base text-white font-semibold text-center w-full cursor-pointer">Block Card Temporarily</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import useCardDetails from '@/composables/Cards/card_details'
import InfoIcon2 from '@/components/icons/InfoIcon2.vue'
export default defineComponent({
  components: { InfoIcon2 },
  setup() {
    const { card_details, selectTab, tab_index } = useCardDetails()

    return { card_details, selectTab, tab_index }
  }
})
</script>

<style></style>
