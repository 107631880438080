import { useLogin } from "@/store/login"
import { useIonRouter } from "@ionic/vue"
import { computed, watch } from "vue"

export default () => {

  const loginModule = useLogin()
  const ionRouter = useIonRouter()

  const loggedIn = computed(() => loginModule.loggedIn)
  const user = computed(() => loginModule.user)
  const login_loader = computed(() => loginModule.login_loader)

  watch(login_loader, () => {
    if (loggedIn.value && !login_loader.value) {
      if (user.value.email === '' || user.value.email === undefined) {
        ionRouter.navigate('/welcome', 'forward', 'replace')
      }
    } else {
      ionRouter.navigate('/welcome', 'forward', 'replace')
    }
  })

  return { login_loader, loggedIn, user }
}