import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-gray-50 max-w-md mx-auto h-screen xs:shadow-2xl relative overflow-x-auto" }
const _hoisted_2 = { class: "px-6 xxs:px-9 py-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_bar_component = _resolveComponent("status-bar-component")!
  const _component_half_icon = _resolveComponent("half-icon")!
  const _component_verification_page = _resolveComponent("verification-page")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "animate__animated animate__fadeInLeft" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isPlatform('iphone'))
              ? (_openBlock(), _createBlock(_component_status_bar_component, {
                  key: 0,
                  color: 'bg-gray-50'
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["absolute right-0 -mt-5", { 'top-0': !_ctx.isPlatform('iphone'), 'top-9': _ctx.isPlatform('iphone') }])
            }, [
              _createVNode(_component_half_icon, {
                color: '#ED6500',
                width: 'w-20',
                height: 'h-20'
              })
            ], 2),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_verification_page)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}