<template>
  <div class="w-full z-40" :class="[color, margin_left_right, height]"></div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  props: {
    color: String,
    margin_left_right: {
      type: String,
      default: 'mx-0'
    },
    height: {
      type: String,
      default: 'h-9'
    }
  },
  setup() {
    //
  }
})
</script>

<style></style>
