<template>
  <svg :class="[width, height]" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.6657" cy="11.6686" r="11.6667" fill="#E33A24" />
    <circle cx="28.3327" cy="11.6686" r="11.6667" fill="#F8CB2E" fill-opacity="0.8" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-11',
      type: String
    },
    height: {
      default: 'h-6',
      type: String
    }
  }
}
</script>

<style></style>
