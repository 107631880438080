
import { ref } from '@vue/reactivity'
import PhoneVerification from './PhoneVerification.vue'
import VerificationCode from './VerificationCode.vue'
import RegisterPage from './RegisterPage.vue'
import { defineComponent } from '@vue/runtime-core'
import useVerificationPage from '@/composables/WelcomePage/verification_page'
import LoaderIcon from '../icons/LoaderIcon.vue'

export default defineComponent({
  components: { PhoneVerification, VerificationCode, RegisterPage, LoaderIcon },
  setup() {
    const confirmation = ref(null)

    const { enter_phone, verify, show_register, login_loader } = useVerificationPage()

    function verifiedPhone(val: boolean) {
      enter_phone.value = false
      verify.value = val
    }
    function showRegister(val: boolean) {
      enter_phone.value = false
      verify.value = false
      show_register.value = val
    }

    function sendConfirmation(val: any) {
      confirmation.value = val
    }

    return {
      verifiedPhone,
      verify,
      enter_phone,
      show_register,
      login_loader,
      showRegister,
      sendConfirmation,
      confirmation
    }
  }
})
