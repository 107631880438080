<template>
  <svg :class="[width, height]" viewBox="0 0 502 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4_20)">
      <path
        d="M167.5 3C79 3 1 3.66787 1 3.66787V114.335H501V3.66787C501 3.66787 405.012 3 330.333 3C294.333 3 296 54 254 54C207.5 54 205.5 2.99999 167.5 3Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4_20"
        x="0"
        y="0"
        :class="[width, height]"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_20" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_20" result="shape" />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-full',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
