
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import PotIcon from '../icons/PotIcon.vue'
export default defineComponent({
  components: { PotIcon },
  setup() {
    const ionRouter = useIonRouter()

    return { ionRouter }
  }
})
