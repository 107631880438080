import { defineStore } from "pinia"
import fb from '@/services/firebase'

export const useAuthorizationCode = defineStore('authorization_code', {
    state: () => ({
        base_64: ''
    }),
    actions: {
        getAuthorizationCode() {
            fb.db.collection('authorization_code').get().then((authorization_codes: any) => {
                authorization_codes.forEach((authorization_code: any) => {
                    this.base_64 = authorization_code.data().base_64
                })
            })
        }
    }
})