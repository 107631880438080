import { ref } from "vue"

export default () => {
  const about = ref(true)
  const transactions = ref(false)
  const villagers = ref(false)

  function toggleBetweenPages(page_1: boolean, page_2: boolean, page_3: boolean) {
    about.value = page_1
    transactions.value = page_2
    villagers.value = page_3
  }

  return { toggleBetweenPages, about, transactions, villagers }
}