import { getCurrentInstance, ref } from "vue"

export default () => {
    const interest = ref(false)
    const percentage = ref(0.03)
    const lock_savings = ref(false)
    const make_public = ref(false)
    const villager_fee = ref(false)
    const fee = ref('')
    const { emit } = getCurrentInstance() as any
    const deduction = ref(0)
    const deduction_percentage = ref(0.25)

    function toggleInterest() {
        if (interest.value) {
            interest.value = false
        } else {
            interest.value = true
        }
    }

    function toggleLockSavings() {
        if (lock_savings.value) {
            lock_savings.value = false
        } else {
            lock_savings.value = true
        }
    }

    function toggleMakePublic() {
        if (make_public.value) {
            make_public.value = false
        } else {
            make_public.value = true
        }
    }

    function toggleVillagerFee() {
        if (villager_fee.value) {
            villager_fee.value = false
        } else {
            villager_fee.value = true
        }
    }

    function goBack() {
        emit('interest-page', false)
    }

    function newAmount(amount: number): number {
        if (interest.value) {
            const newAmount = amount + (amount * percentage.value)
            deduction.value = (amount * percentage.value) * deduction_percentage.value
            return newAmount
        } else {
            deduction.value = 0
            return amount
        }
    }
    return { toggleInterest, interest, goBack, percentage, lock_savings, fee, villager_fee, toggleVillagerFee, make_public, toggleMakePublic, toggleLockSavings, newAmount, deduction, deduction_percentage }
}