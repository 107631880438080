
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent, onMounted } from '@vue/runtime-core'
import TowncrierCard from '@/components/Announcements/TowncrierCard.vue'
import useAnnouncement from '@/composables/Announcement/announcement'
import VillageMarket from '@/components/Announcements/VillageMarket.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, ArrowLeftIcon, TowncrierCard, VillageMarket, SearchIcon, StatusBarComponent },
  setup(props, context) {
    const ionRouter = useIonRouter()
    const { towncrier, loadTab } = useAnnouncement()
    onMounted(() => {
      loadTab(context.attrs.page_id as any)
    })
    return { ionRouter, towncrier, isPlatform }
  }
})
