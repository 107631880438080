import { ref } from "vue"

export default () => {
  const card_details = ref([
    { content: 'Mene Blessing', title: 'Card Name' },
    { content: '4169 5555 4434 2221', title: 'Card Number' },
    { content: '233', title: 'CVV' },
    { content: 'April 19, 2022', title: 'Expiry date' },
    { content: '21, Gani Adebayo', title: 'Billing address' },
    { content: '23401', title: 'Zip code' },
    { content: 'Lekki', title: 'City' },
    { content: 'Lagos', title: 'State' }
  ])

  const tab_index = ref('')
    function selectTab(index: string) {
      tab_index.value = index
    }

  return { card_details, selectTab, tab_index }
}