
import CreateCard from '@/components/CardsPage/Create/CreateCard.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    CreateCard,
  },
  setup() {
    const ionRouter = useIonRouter()

    return { ionRouter }
  }
})
