
import EmailIcon from '../icons/EmailIcon.vue'
import ReferralIcon from '../icons/ReferralIcon.vue'
import UserIcon from '../icons/UserIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import useRegisterUser from '@/composables/WelcomePage/register_user'

export default defineComponent({
  components: { UserIcon, EmailIcon, ReferralIcon },
  setup() {
    const { fullname, email, referral_code, registerUser, add_user_loader } = useRegisterUser()
    return { registerUser, fullname, email, referral_code, add_user_loader }
  }
})
