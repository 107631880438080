<template>
  <div>
    <announcement-refer></announcement-refer>
    <annoucement-list-card></annoucement-list-card>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import AnnoucementListCard from './AnnoucementListCard.vue'
import AnnouncementRefer from './AnnouncementRefer.vue'
export default defineComponent({
  components: { AnnouncementRefer, AnnoucementListCard }
})
</script>

<style></style>
