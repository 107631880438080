
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import CardDetails from '@/components/CardsPage/View/CardDetails.vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    HalfIcon,
    CardDetails
  },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
