
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import ToMySavingsPot from '@/components/SaveMoney/ToMySavingsPot.vue'
import ToVillagePot from '@/components/SaveMoney/ToVillagePot.vue'
import { defineComponent } from '@vue/runtime-core'
import PersonWithPhoneIcon from '@/components/icons/PersonWithPhoneIcon.vue'
import SaveMoneyAmount from '@/components/SaveMoney/SaveMoneyAmount.vue'
import useSaveMoneyPage from '@/composables/SaveMoney/save_money_page'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HalfIcon,
    ArrowLeftIcon,
    ToMySavingsPot,
    ToVillagePot,
    PersonWithPhoneIcon,
    SaveMoneyAmount,
    StatusBarComponent
  },
  setup() {
    const ionRouter = useIonRouter()
    const { amount_page, pot, acceptPageValues } = useSaveMoneyPage()
    return { ionRouter, amount_page, pot, acceptPageValues, isPlatform }
  }
})
