
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import useListAnnouncements from '@/composables/Announcement/list_announcements'
import ShareIcon from '../icons/ShareIcon.vue'
import SpeakerIcon from '../icons/SpeakerIcon.vue'
import LikeIcon from '../icons/LikeIcon.vue'

export default defineComponent({
  components: { ShareIcon, SpeakerIcon, LikeIcon },
  setup() {
    const ionRouter = useIonRouter()
    const { announcements } = useListAnnouncements()
    return { ionRouter, announcements }
  }
})
