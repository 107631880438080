<template>
  <div class="w-screen md:max-w-md relative">
    <div class="fixed bottom-0 left-0 right-0 w-full md:max-w-md mx-auto text-center">
      <div
        class="bg-gray-50 rounded-full w-14 h-14 mx-auto shadow hover:shadow-lg cursor-pointer -mb-6 py-1 opacity-80 hover:opacity-100"
        @click="() => ionRouter.navigate('/create_pots', 'forward', 'replace')"
      >
        <div class="bg-card-100 rounded-full w-12 h-12 mx-auto">
          <div class="text-3xl text-gray-50 text-center font-bold py-1">+</div>
        </div>
      </div>
      <footer-plate></footer-plate>
      <div class="absolute bottom-5 w-full flex h-8 xxs:h-10 xs:h-14 items-center">
        <div class="grid grid-cols-5 gap-x-6 mx-5 w-full">
          <div @click="() => ionRouter.navigate('/home', 'forward', 'replace')">
            <home-icon :width="'w-6 xs:w-8 mt-2 cursor-pointer'" v-if="active != 'home'"></home-icon>
            <home-icon :width="'w-6 xs:w-8 mt-2 cursor-pointer'" :color="'#ED6500'" v-if="active == 'home'"></home-icon>
          </div>
          <div @click="() => ionRouter.navigate('/list_personal_pots', 'forward', 'replace')">
            <menu-pot-icon :width="'w-8 xs:w-10 cursor-pointer'" v-if="active != 'personal'"></menu-pot-icon>
            <menu-pot-icon :width="'w-8 xs:w-10 cursor-pointer'" v-if="active == 'personal'"></menu-pot-icon>
          </div>
          <div></div>
          <div @click="() => ionRouter.navigate('/list_village_pots', 'forward', 'replace')">
            <people-icon :width="'w-6 xs:w-8 mt-1 cursor-pointer'" v-if="active != 'village'"></people-icon>
            <people-icon :width="'w-6 xs:w-8 mt-1 cursor-pointer'" v-if="active == 'village'"></people-icon>
          </div>
          <div @click="() => ionRouter.navigate('/cards', 'forward', 'replace')">
            <card-icon :width="'w-6 xs:w-8 mt-1 cursor-pointer'" v-if="active != 'card'"></card-icon>
            <card-icon :width="'w-6 xs:w-8 mt-1 cursor-pointer'" v-if="active == 'card'"></card-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useIonRouter } from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import CardIcon from './icons/CardIcon.vue'
import FooterPlate from './icons/FooterPlate.vue'
import HomeIcon from './icons/HomeIcon.vue'
import MenuPotIcon from './icons/MenuPotIcon.vue'
import PeopleIcon from './icons/PeopleIcon.vue'
export default defineComponent({
  components: { FooterPlate, HomeIcon, MenuPotIcon, PeopleIcon, CardIcon },
  props: {
    active: String
  },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter }
  }
})
</script>

<style></style>
