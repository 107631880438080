import { useIonRouter } from "@ionic/vue"
import Swal from "sweetalert2"
import { ref } from "vue"

export default () => {
  const cards = ref([
    {
      card_name: "Devon Lane",
      card_number: '**** **** **** 0329',
      card_type: "Naira MasterCard",
      total_amount: '#50,000',
      valid_thru: '03/24',
      is_disabled: false
    },
    {
      card_name: "Devon Lane",
      card_number: '**** **** **** 0329',
      card_type: "Dollar MasterCard",
      total_amount: '$500.67',
      valid_thru: '03/24',
      is_disabled: false
    },
    {
      card_name: "Devon Lane",
      card_number: '**** **** **** 0329',
      card_type: "Naira MasterCard",
      total_amount: '#50,000',
      valid_thru: '03/24',
      is_disabled: false
    },
    {
      card_name: "Devon Lane",
      card_number: '**** **** **** 0329',
      card_type: "Naira MasterCard",
      total_amount: '#50,000',
      valid_thru: '03/24',
      is_disabled: false
    }
  ])

  const ionRouter = useIonRouter()
  const selected_card = ref(false)
  const selected_card_index = ref(0)
  const icon_content = ref('') as any
  const title = ref('') as any
  const timer = ref(0)

  const selectCard = (index: number, card: boolean) => {
    if (card) {
      selected_card.value = false
      selected_card_index.value = 0
    } else if (!card) {
      selected_card.value = true
      selected_card_index.value = index
    }
  }

  const cardDetails = () => {
    if (selected_card.value == true) {
      if (cards.value[selected_card_index.value].is_disabled === false) {
        ionRouter.navigate('/view_card_details', 'forward', 'replace')
      } else if (cards.value[selected_card_index.value].is_disabled === true) {
        callToast('error', 'This card has been blocked', 4000)
      }
    } else if (selected_card.value == false) {
      callToast('info', 'Please select a card', 3000)
    }
  }

  const disableCard = () => {
    if (selected_card.value == true) {
      cards.value[selected_card_index.value].is_disabled = true
      selected_card.value = false
      callToast('success', 'Card block successfully', 3000)
    } else if (selected_card.value == false) {
      callToast('info', 'Please select a card to block', 3000)
    }
  }

  const enableCard = () => {
    if (selected_card.value == true) {
      if (cards.value[selected_card_index.value].is_disabled) {
        cards.value[selected_card_index.value].is_disabled = false
        selected_card.value = false
        callToast('success', 'Card unblock successfully', 3000)
      } else if (cards.value[selected_card_index.value].is_disabled === false) {
        callToast('info', 'This card is not blocked', 3000)
      }
    } else if (selected_card.value == false) {
      callToast('info', 'Please select a card to Unblock', 3000)
    }
  }

  function finish() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: timer.value,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: icon_content.value,
      title: title.value
    })
  }

  function callToast(icon: string, header: string, time: number) {
    icon_content.value = icon
    title.value = header
    timer.value = time
    finish()
  }

  return { cards, selectCard, cardDetails, selected_card, selected_card_index, disableCard, enableCard }
}