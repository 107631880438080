<template>
  <svg :class="[width, height]" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 3H22.5C23.2956 3 24.0587 3.31607 24.6213 3.87868C25.1839 4.44129 25.5 5.20435 25.5 6V9H22.5V6H9V30H22.5V27H25.5V30C25.5 30.7956 25.1839 31.5587 24.6213 32.1213C24.0587 32.6839 23.2956 33 22.5 33H9C8.20435 33 7.44129 32.6839 6.87868 32.1213C6.31607 31.5587 6 30.7956 6 30V6C6 5.20435 6.31607 4.44129 6.87868 3.87868C7.44129 3.31607 8.20435 3 9 3Z"
      :fill="color"
    />
    <path
      d="M24.135 23.385L26.25 25.5L33.75 18L26.25 10.5L24.135 12.615L28.005 16.5H13.5V19.5H28.005L24.135 23.385Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-6',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    },
    color: {
      default: '#000022',
      type: String
    }
  }
}
</script>

<style></style>
