import { ref, watchEffect } from "vue"

export default () => {
    const timeline = ref([
        {
            title: '3 Months',
            interval_count: [
                { count: 90, words: '90 Days', time: 'Every Day' },
                { count: 12, words: '12 Weeks', time: 'Every Week' },
                { count: 6, words: '6 Weeks', time: 'Every 2 Weeks' },
                { count: 3, words: '3 Months', time: 'Every Month' }
            ],
            days: 90
        },
        {
            title: '6 Months',
            interval_count: [
                { count: 180, words: '180 Days', time: 'Every Day' },
                { count: 24, words: '24 Weeks', time: 'Every Week' },
                { count: 12, words: '12 Weeks', time: 'Every 2 Weeks' },
                { count: 6, words: '6 Months', time: 'Every Month' }
            ],
            days: 182
        },
        {
            title: '1 Year',
            interval_count: [
                { count: 365, words: '365 Days', time: 'Every Day' },
                { count: 48, words: '48 Weeks', time: 'Every Week' },
                { count: 24, words: '24 Weeks', time: 'Every 2 Weeks' },
                { count: 12, words: '12 Months', time: 'Every Month' }
            ],
            days: 365
        }
    ])
    const frequency_index = ref(null)
    const duration_index = ref(null)
    const pot_name = ref('')
    const amount = ref('')
    const total_amount = ref(0)
    const forward_date = ref(Date.now())
    const interest_page = ref(false)

    watchEffect(() => {
        calculateSavings()
        calculateDate()
    })

    function calculateSavings() {
        if (duration_index.value !== null && frequency_index.value !== null && amount.value !== '') {
            total_amount.value = parseInt(amount.value) * timeline.value[duration_index.value].interval_count[frequency_index.value].count
        }
    }

    function calculateDate() {
        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth()
        const day = d.getDate()
        const hour = d.getHours()
        const minutes = d.getMinutes()
        if (duration_index.value !== null) {
            forward_date.value = new Date(year, month, day + timeline.value[duration_index.value].days, hour, minutes).getTime()
        }
    }

    function validator(): boolean {
        if (frequency_index.value !== null && duration_index.value !== null && pot_name.value !== null && amount.value !== null) {
            return true
        } else {
            return false
        }
    }

    function nextPage() {
        if (validator()) {
            interest_page.value = true
        }
    }
    return { timeline, frequency_index, duration_index, pot_name, amount, total_amount, forward_date, nextPage, interest_page }
}