
import { IonContent, IonPage, useBackButton, useIonRouter } from '@ionic/vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent, onMounted } from '@vue/runtime-core'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import PersonalPotsGraph from '@/components/PersonalPot/View/PersonalPotsGraph.vue'
import PersonalPotDetails from '@/components/PersonalPot/View/PersonalPotDetails.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'
//For Android Only
import { StatusBar } from '@capacitor/status-bar'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    ArrowLeftIcon,
    CalendarIcon,
    PersonalPotsGraph,
    PersonalPotDetails,
    StatusBarComponent
  },
  setup() {
    const ionRouter = useIonRouter()
    useBackButton(10, () => {
      ionRouter.navigate('/home', 'forward', 'replace')
    })

    onMounted(() => {
      let googleScript = document.createElement('script')
      googleScript.setAttribute('src', 'https://www.gstatic.com/charts/loader.js')
      document.head.appendChild(googleScript)

      if (isPlatform('android')) {
        StatusBar.setBackgroundColor({ color: '#ED6500' })
      }
    })

    return { ionRouter, isPlatform }
  }
})
