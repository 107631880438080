import { ref } from "vue"

export default () => {
    const market = ref([
        { 
            content: 'Grab this Holiday Package. Proudly sponsored by Jumia Travels. Only 10 slots available.', 
            old_amount: 560000, 
            new_amount: 300000, 
            slots: 5, 
            deadline: '19 hours ago' 
        },
        { 
            content: 'Book a flight from Lagos to Abuja at a discounted rate.', 
            old_amount: 50000, 
            new_amount: 28000, 
            slots: 21, 
            deadline: '1 day ago' 
        },
        { 
            content: 'Get an Alienware gaming laptop at a discounted rate, Hurry!!', 
            old_amount: 750000, 
            new_amount: 450000, 
            slots: 3, 
            deadline: '4 day ago' 
        }
    ])
    return { market }
}