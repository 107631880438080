
import { ref } from '@vue/reactivity'
import InflowIcon from '@/components/icons/InflowIcon.vue'
import OutflowIcon from '@/components/icons/OutflowIcon.vue'
export default {
  components: { InflowIcon, OutflowIcon },
  setup() {
    const count = ref([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29
    ])
    return { count }
  }
}
