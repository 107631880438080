<template>
  <svg :class="[width, height]" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 18.5H10.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.5 20.5V16.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8.1606 9.87C8.0606 9.86 7.9406 9.86 7.8306 9.87C5.45058 9.79 3.56058 7.84 3.56058 5.44C3.55058 2.99 5.54058 1 7.9906 1C10.4406 1 12.4306 2.99 12.4306 5.44C12.4306 7.84 10.5306 9.79 8.1606 9.87Z"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.9891 20.8097C6.1691 20.8097 4.35906 20.3497 2.97906 19.4297C0.559062 17.8097 0.559062 15.1697 2.97906 13.5597C5.72906 11.7197 10.2391 11.7197 12.9891 13.5597"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: 'w-4',
      type: String
    },
    height: {
      default: 'h-auto',
      type: String
    }
  }
}
</script>

<style></style>
