<template>
  <ion-page class="animate__animated animate__fadeInLeft">
    <ion-content :fullscreen="true">
      <div class="bg-gray-50 max-w-md mx-auto min-h-screen xs:shadow-2xl relative overflow-x-auto">
        <status-bar-component v-if="isPlatform('iphone')" :color="'bg-gray-50'"></status-bar-component>
        <div class="absolute right-0 -mt-5" :class="{ 'top-0': !isPlatform('iphone'), 'top-9': isPlatform('iphone') }">
          <half-icon :color="'#ED6500'" :width="'w-20'" :height="'h-20'"></half-icon>
        </div>
        <div
          class="absolute left-5 cursor-pointer"
          :class="{ 'top-3': !isPlatform('iphone'), 'top-12': isPlatform('iphone') }"
          @click="() => ionRouter.navigate('/add_money', 'forward', 'replace')"
        >
          <arrow-left-icon :width="'w-4'" :color="'#474747'"></arrow-left-icon>
        </div>
        <div class="px-4 xxs:px-6 py-14">
          <div class="text-xl text-center font-bold">Add Money using Bank Transfer (FREE)</div>
          <div class="pt-8">The account number provided below is unique to your Village Square account.</div>
          <div class="pt-5 text-card-100 font-bold">Follow below step-by-step instructions</div>
          <div class="py-3">
            <div class="font-bold flex"><span class="pr-2">1.</span> <span>Copy your account details below</span></div>
          </div>
          <div class="py-4 px-6 bg-main-700 rounded-lg">
            <div>
              <div>Bank Name</div>
              <div class="text-lg font-bold leading-5">Providus Bank</div>
            </div>
            <div class="pt-4">
              <div>Account Name</div>
              <div class="text-lg font-bold leading-5">Ajayi Mayowa</div>
            </div>
            <div class="pt-4 pb-1">
              <div>Account Number</div>
              <div class="text-lg font-bold leading-5 select-text">6767578934 (Savings Account)</div>
            </div>
          </div>
          <div class="py-3">
            <div class="font-bold flex">
              <span class="pr-2">2.</span>
              <span>Transfer the amount your want to add using mobile/internet banking</span>
            </div>
          </div>
          <div class="py-3">
            <div class="font-bold flex">
              <span class="pr-2">3.</span>
              <span>Your Village Square balance will be funded immediately</span>
            </div>
          </div>
          <div class="bg-card-150 rounded-3xl p-2 hover:shadow-lg">
            <div class="bg-card-100 rounded-2xl px-4 py-3">
              <div class="flex justify-between items-center">
                <div class="text-xl text-gray-50 font-semibold text-center w-full cursor-pointer">
                  Copy Account Number
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: { IonContent, IonPage, HalfIcon, ArrowLeftIcon, StatusBarComponent },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter, isPlatform }
  }
})
</script>

<style></style>
