<template>
  <div class="absolute inset-0 bg-white z-50" v-if="loading">
    <div class="items-center justify-center flex h-screen">
      <div>
        <img src="/loader.gif" class="w-44 mx-auto" />
        <div class="-mt-9 text-center text-gray-700">loading{{ dots }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  data() {
    return {
      dots: '.',
      number: 1
    }
  },
  methods: {
    controlDots() {
      if (this.number == 1) {
        this.dots = '.'
      } else if (this.number == 2) {
        this.dots = '..'
      } else if (this.number == 3) {
        this.dots = '...'
      }
    },
    incrementNumber() {
      if (this.number < 3) {
        this.number++
      } else if (this.number == 3) {
        this.number = 1
      }
      this.controlDots()
    }
  },
  mounted() {
    setInterval(() => {
      this.incrementNumber()
    }, 500)
  }
}
</script>

<style></style>
