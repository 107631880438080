
import { defineComponent } from '@vue/runtime-core'
import useAboutVillagePot from '@/composables/VillagePot/about'
export default defineComponent({
  components: {},
  setup() {
    const { about_text, read_more } = useAboutVillagePot()
    return { about_text, read_more }
  }
})
