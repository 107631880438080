import useAccessToken from '@/services/access_token'
import { useAddUserDetails } from "@/store/add_user_details"
import useDateConversion from '@/composables/date_conversion'
import { computed, onMounted, watch } from 'vue'
import { useLogin } from "@/store/login"

export default () => {
    const dateConversion = useDateConversion()
    const loginModule = useLogin()
    const user = computed(() => loginModule.user)
    const { accessToken } = useAccessToken()

    watch(user.value, () => {
        checkIfAccessTokenExists()
    })

    const checkIfAccessTokenExists = () => {
        if (user.value.access_token !== undefined && user.value.access_token.token === null) {
            getToken()
        } else {
            checkIfAccessTokenIsExpired()
        }
    }

    const checkIfAccessTokenIsExpired = () => {
        if (user.value.access_token !== undefined && user.value.access_token.expires_at !== null) {
            const dateNow = Date.now()
            const expires_at = user.value.access_token.expires_at
            if (expires_at !== null) {
                if (dateNow > expires_at) {
                    console.log('expired')
                    getToken()
                } else {
                    console.log('not expired')
                }
            }
        }
    }

    const getToken = () => {
        accessToken().then((res: any) => {
            console.log(res)
            const convertToMinutes = Math.floor(res.data.expires_in / 60)
            const expiresAt = dateConversion.addToDate({ year: 0, month: 0, day: 0, hour: 0, minutes: convertToMinutes })
            const params = {
                token: res.data.access_token,
                expires_at: expiresAt,
                uid: user.value.uid
            }
            useAddUserDetails().addAccessToken(params)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    onMounted(() => {
        checkIfAccessTokenExists()
    })
    return {}
}