
import { IonContent, IonPage, useIonRouter } from '@ionic/vue'
import HalfIcon from '@/components/icons/HalfIcon.vue'
import { defineComponent } from '@vue/runtime-core'
import AmountToSend from '@/components/SendMoney/AmountToSend.vue'
import StatusBarComponent from '@/components/StatusBar.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HalfIcon,
    AmountToSend,
    StatusBarComponent
  },
  setup() {
    const ionRouter = useIonRouter()
    return { ionRouter, isPlatform }
  }
})
