import { useLogin } from "@/store/login"
import { useIonRouter } from "@ionic/vue"
import { computed, ref, watch } from "vue"

export default () => {
    const enter_phone = ref(true)
    const verify = ref(false)
    const show_register = ref(false)
    const loginModule = useLogin()
    const ionRouter = useIonRouter()

    const loggedIn = computed(() => loginModule.loggedIn)
    const user = computed(() => loginModule.user)
    const login_loader = computed(() => loginModule.login_loader)

    watch(login_loader, () => {
        if (loggedIn.value && !login_loader.value) {
            enter_phone.value = false
            if (user.value.email === '' || user.value.email === undefined) {
                show_register.value = true
            } else if (user.value.email !== '') {
                ionRouter.navigate('/home', 'forward', 'replace')
            }
        }
    })
    return { loggedIn, user, enter_phone, verify, show_register, login_loader }
}